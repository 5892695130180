type Config = {
  firebaseAuth: {
    apiKey: string
    authDomain: string
    projectID: string
  }
}

const prod: Config = {
  firebaseAuth: {
    apiKey: "AIzaSyCmkOkzwLuoMR6ntCPwubdFruKo89DEpB0", // gitleaks:allow - firebase API keys are not secrets
    authDomain: "einride-aet-vpn-v2.web.app",
    projectID: "einride-aet-vpn",
  },
}

const dev: Config = {
  firebaseAuth: {
    apiKey: "AIzaSyC8o4_qTF86nLnbi091OEG4SqY3jnzsXtU", // gitleaks:allow - firebase API keys are not secrets
    authDomain: "einride-aet-vpn-dev-v2.web.app",
    projectID: "einride-aet-vpn-dev",
  },
}

const local: Config = {
  firebaseAuth: {
    apiKey: "AIzaSyC8o4_qTF86nLnbi091OEG4SqY3jnzsXtU", // gitleaks:allow - firebase API keys are not secrets
    authDomain: "einride-aet-vpn-dev-v2.web.app",
    projectID: "einride-aet-vpn-dev",
  },
}

export const configs: Record<string, Config> = {
  "v2.ro.einride.systems": prod,
  "v2.ro.einride.dev": dev,
  localhost: local,
  default: dev,
}

export const getConfig = (): Config => {
  const source = window.location.hostname

  if (configs[source]) {
    return configs[source]
  }

  return configs.default
}
