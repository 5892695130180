// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/vehicle_service.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetVehicleRequest, ListVehiclesRequest, ListVehiclesResponse, StreamVehicleLiveDataRequest } from "./vehicle_service_pb.js";
import { Vehicle } from "./vehicle_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { StreamVehicleLiveDataResponse } from "./vehicle_live_pb.js";

/**
 * Vehicle service.
 *
 * @generated from service einride.rd_operator_interface.v1.VehicleService
 */
export const VehicleService = {
  typeName: "einride.rd_operator_interface.v1.VehicleService",
  methods: {
    /**
     * Get a vehicle.
     *
     * @generated from rpc einride.rd_operator_interface.v1.VehicleService.GetVehicle
     */
    getVehicle: {
      name: "GetVehicle",
      I: GetVehicleRequest,
      O: Vehicle,
      kind: MethodKind.Unary,
    },
    /**
     * List vehicles.
     * (-- api-linter: core::0132::method-signature=disabled --)
     * (-- api-linter: core::0132::http-uri-parent=disabled --)
     *
     * @generated from rpc einride.rd_operator_interface.v1.VehicleService.ListVehicles
     */
    listVehicles: {
      name: "ListVehicles",
      I: ListVehiclesRequest,
      O: ListVehiclesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Stream vehicle live data.
     *
     * @generated from rpc einride.rd_operator_interface.v1.VehicleService.StreamVehicleLiveData
     */
    streamVehicleLiveData: {
      name: "StreamVehicleLiveData",
      I: StreamVehicleLiveDataRequest,
      O: StreamVehicleLiveDataResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
} as const;

