import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  position: absolute;
`

export const TopRightFlexDiv = styled(FlexDiv)`
  right: 0;
  top: 0;
  z-index: 1;
`

const fadeInOut = keyframes`
  0% {
    transform: scale(.96);
    opacity: 0;
    visibility: hidden;
  }
   5% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  
     75% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
    100% {
    transform: scale(.96);
    opacity: 0;
    visibility: hidden;
  }
`

export const BottomMidFlexDiv = styled(FlexDiv)`
  display: inline-block;
  animation: ${fadeInOut} 5s ease;
  left: 50%;
  bottom: 2vh;
  z-index: 1;
  animation-fill-mode: forwards;
  justify-content: flex-start;
`
