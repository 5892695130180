import { PrimaryButton } from "@einride/ui"
import { useAuth } from "contexts/AuthContext"
import React from "react"
import { useNavigate } from "react-router-dom"

type LogoutProps = {
  children: React.ReactNode
}

export const Logout = ({ children }: LogoutProps): React.JSX.Element => {
  const auth = useAuth()
  if (auth === null) {
    throw new Error("Logout can only be used inside AuthContext")
  }
  const navigate = useNavigate()
  const handleClick = (): void => {
    auth
      .signOut()
      .then(() => {
        navigate("/")
      })
      .finally(() => {
        navigate(0)
      })
  }
  return <PrimaryButton onClick={handleClick}>{children}</PrimaryButton>
}
