import {
  FlyToInterpolator,
  MapViewState,
  PickingInfo,
  WebMercatorViewport,
} from "@deck.gl/core/typed"
import DeckGL from "@deck.gl/react/typed"
import { Box, Card, Group, Segments, SegmentsItem, useColorScheme } from "@einride/ui"
import { Layer } from "@emotion-icons/boxicons-solid"
import bbox from "@turf/bbox"
import { StreamVehicleLiveDataResponse } from "gen/einride/rd_operator_interface/v1/vehicle_live_pb"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import { FeatureCollection, LineString, Point } from "geojson"
import moment from "moment/moment"
import React, { useEffect, useMemo, useRef, useState } from "react"
import MapGl, { MapRef } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { useAnimatedPathLayer } from "./AnimatedPathLayer"
import { CustomIconButton } from "./customComponents"
import { useGeoJsonLayers } from "./GeoJsonMapLayers"
import { useGoalLayer } from "./GoalMapLayer"
import { Base64ToFeatureCollection, PathLength, PathMaxSpeed } from "./PathComponents"
import { useVehiclePositionLayers } from "./VehicleMapLayers"
import { useVehicleObjectLayers } from "./VehicleObjectMapLayers"

type Props = {
  localPath?: FeatureCollection<LineString | Point>
  vehicleLiveData?: StreamVehicleLiveDataResponse
  vehicle?: Vehicle
}

const mapStyleConfig = {
  light: "mapbox://styles/einride-portal/cl9pffnl8003r14vqtdvg5val",
  dark: "mapbox://styles/einride-portal/cl9pfirmr000u14molltrlf3y",
}
const mapStyleConfigSatellite = "mapbox://styles/mapbox/satellite-v9"

type mapViewMode = "default" | "satellite"

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZWlucmlkZS1wb3J0YWwiLCJhIjoiY2syNGp6OXl6MGVwYTNxcDloaDJybmF6OSJ9.Qi4aTN6veQF-MdHv0_QJ7w"

export const GeoJSONMap: React.FC<Props> = ({ localPath, vehicleLiveData, vehicle }) => {
  const [pathToDisplay, setPathToDisplay] = useState<FeatureCollection<LineString | Point>>()

  // The path and trajectory the vehicle is currently following
  const [vehiclePath, setVehiclePath] = useState<FeatureCollection<LineString | Point>>()
  // We store the vehicle path in base64 aswell to be able to easily compare if the path has been updated
  const [vehiclePathInBase64, setVehiclePathInBase64] = useState<string>()
  const [vehicleTrajectory, setVehicleTrajectory] =
    useState<FeatureCollection<LineString | Point>>()

  const [mapViewMode, setMapViewMode] = useState<mapViewMode>("default")
  const [openSelect, setOpenSelect] = useState<boolean>(false)
  const mapContainer = useRef<MapRef>()
  const { colorScheme } = useColorScheme()
  const map = mapContainer.current
  const [viewState, setViewState] = useState<MapViewState>({
    longitude: 0,
    latitude: 30,
    zoom: 1,
    maxZoom: 22,
    minZoom: 1,
  })

  const view = useMemo(() => {
    return new WebMercatorViewport({
      width: 0.6 * window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  useEffect(() => {
    if (vehicleLiveData?.path && vehicleLiveData.path.length !== 0) {
      // We only want to update the path (and animate to it) if it is different from the previous path
      // since we get the path every second
      const decoded = new TextDecoder().decode(vehicleLiveData.path)
      if (!vehiclePathInBase64 || vehiclePathInBase64 !== decoded) {
        setVehiclePath(Base64ToFeatureCollection(decoded))
        setVehiclePathInBase64(decoded)
      }
    } else {
      setVehiclePath(undefined)
    }

    if (vehicleLiveData?.trajectory && vehicleLiveData.trajectory.length !== 0) {
      const decoded = new TextDecoder().decode(vehicleLiveData.trajectory)
      setVehicleTrajectory(Base64ToFeatureCollection(decoded))
    } else {
      setVehicleTrajectory(undefined)
    }
  }, [vehicleLiveData, vehiclePathInBase64, vehicleTrajectory])

  useEffect(() => {
    // If a local path is selected, display that, else show the vehicles current path
    setPathToDisplay(localPath ?? vehiclePath)
  }, [localPath, vehiclePath])

  const handleViewStateChange = (vs: MapViewState): void => {
    setViewState({
      zoom: vs.zoom,
      longitude: vs.longitude,
      latitude: vs.latitude,
      transitionDuration: "auto",
      transitionEasing: (t: number): number => {
        return Math.sqrt(1 - (t - 1) ** 2)
      },
      transitionInterpolator: new FlyToInterpolator(),
    })
  }

  useEffect(() => {
    if (!map || !pathToDisplay) {
      return
    }
    const [minLng, minLat, maxLng, maxLat] = bbox(pathToDisplay)
    const { latitude, longitude, zoom } = view.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      { padding: 100, maxZoom: 18 },
    )

    handleViewStateChange({
      zoom,
      longitude,
      latitude,
    })
  }, [pathToDisplay, map, view])

  const { vehiclePositionLayers, vehicleIsLive } = useVehiclePositionLayers(
    vehicle,
    vehicleLiveData,
  )

  const { vehicleObjectLayers } = useVehicleObjectLayers(vehicle, vehicleLiveData)
  const pathLayer = useGeoJsonLayers(pathToDisplay)
  const trajectoryLayer = useGeoJsonLayers(vehicleTrajectory, [255, 255, 0, 255])
  const goalLayer = useGoalLayer(pathToDisplay)
  // A ghost truck will follow the localpath (i.e. the path that the operator has selected but the truck is not following)
  const animatedPathLayer = useAnimatedPathLayer(localPath)
  const layers = [
    ...pathLayer,
    ...trajectoryLayer,
    ...vehiclePositionLayers,
    ...vehicleObjectLayers,
    animatedPathLayer,
    goalLayer,
  ]

  const handleVehicleClick = (pickingInfo: PickingInfo): void => {
    const vehicleLayerData = pickingInfo.layer?.props.data as [StreamVehicleLiveDataResponse]
    if (
      pickingInfo.layer?.props.id === "vehicle" &&
      vehicleLayerData.length > 0 &&
      vehicleLayerData[0].position?.coordinate
    ) {
      handleViewStateChange({
        zoom: 14,
        longitude: vehicleLayerData[0].position?.coordinate?.longitude || 0,
        latitude: vehicleLayerData[0].position?.coordinate?.latitude || 0,
      })
    }
  }

  return (
    <DeckGL
      initialViewState={viewState}
      controller
      layers={layers}
      style={{ height: "100vh", width: "100%", position: "relative", overflowX: "hidden" }}
      getTooltip={(pickingInfo) => {
        const vehicleInfo = pickingInfo.object as StreamVehicleLiveDataResponse | undefined
        if (vehicleInfo === undefined) {
          return null
        }
        if (!vehicleIsLive) {
          return `Live position unavailable.
          Last seen ${moment(vehicleInfo.position?.updateTime?.toDate()).fromNow()}.`
        }
        return `Latest battery charge: ${vehicleInfo.battery?.toFixed(0)}%`
      }}
      onClick={(pickingInfo) => {
        handleVehicleClick(pickingInfo)
      }}
    >
      <MapGl
        mapboxAccessToken={MAPBOX_TOKEN}
        ref={(ref) => {
          mapContainer.current = ref || undefined
        }}
        mapStyle={
          mapViewMode === "satellite" ? mapStyleConfigSatellite : mapStyleConfig[colorScheme]
        }
        interactiveLayerIds={["vehicle"]}
      />
      <CustomIconButton
        aria-label="map-type"
        style={{ position: "absolute", right: "16px", bottom: "24px" }}
        onClick={() => setOpenSelect(true)}
        onMouseEnter={() => setOpenSelect(true)}
        onMouseLeave={() => setOpenSelect(false)}
      >
        <Box display="flex" flexDirection="row">
          <Box hidden={!openSelect}>
            <Segments
              onValueChange={(value) => setMapViewMode(value as mapViewMode)}
              value={mapViewMode}
            >
              <SegmentsItem value="default">default</SegmentsItem>
              <SegmentsItem value="satellite">satellite</SegmentsItem>
            </Segments>
          </Box>
          <Layer size="24px" style={{ margin: "13.5px" }} />{" "}
          {/* segment is 51px high and icon 24px => (51-24)/2=13.5 */}
        </Box>
      </CustomIconButton>
      {pathToDisplay === undefined ? null : <PathDetails path={pathToDisplay} />}
    </DeckGL>
  )
}

const PathDetails = (props: { path: FeatureCollection<LineString | Point> }): React.JSX.Element => {
  const { path } = props
  return (
    <Group style={{ position: "absolute", left: "16px", bottom: "26px" }}>
      <Card>
        <PathLength path={path} />
      </Card>
      <Card>
        <PathMaxSpeed path={path} />
      </Card>
    </Group>
  )
}
