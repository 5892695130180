import { Caption } from "@einride/ui"
import Ajv from "ajv"
import { GeoJsonFeatureCollectionSchema } from "assets/FeatureCollection"
import { useUnitSystem } from "components/SettingsSheet"
import { LatLng } from "gen/google/type/latlng_pb"
import { Feature, FeatureCollection, LineString, Point } from "geojson"
import { ReactElement } from "react"

const filterPath = (path: FeatureCollection<LineString | Point>): Feature<LineString>[] => {
  return path.features.filter((f) => f.geometry.type === "LineString") as Feature<LineString>[]
}

const distance = (lamda1: number, phi1: number, lamda2: number, phi2: number): number => {
  const R = 6371000
  const deltaLamda = ((lamda2 - lamda1) * Math.PI) / 180
  const newPhi1 = (phi1 * Math.PI) / 180
  const newPhi2 = (phi2 * Math.PI) / 180
  const x = deltaLamda * Math.cos((newPhi1 + newPhi2) / 2)
  const y = newPhi2 - newPhi1
  const d = Math.sqrt(x * x + y * y)
  return R * d
}

const FindMaxSpeed = (
  path: FeatureCollection<LineString | Point>,
): { maxSpeed: string; speedUnit: string } => {
  const unitSystem = useUnitSystem()
  if (!path) {
    return { maxSpeed: "", speedUnit: "" }
  }

  const value = filterPath(path).reduce(
    (maxSpeed, f) => Math.max(maxSpeed, f.properties?.maxSpeedMetresPerSecond ?? 0),
    0,
  )
  const mpsToKph = 3.6
  const mpsToMph = 3600 / 1609.34

  if (unitSystem === "US") {
    return { maxSpeed: (value * mpsToMph).toFixed(2), speedUnit: "mph" }
  }
  return { maxSpeed: (value * mpsToKph).toFixed(2), speedUnit: "km/h" }
}

const CalculateLength = (
  path: FeatureCollection<LineString | Point>,
): { length: string; lengthUnit: string } => {
  const unitSystem = useUnitSystem()
  if (!path) {
    return { length: "", lengthUnit: "" }
  }

  const value = filterPath(path)
    .map((f) => {
      let result = 0
      for (let i = 1; i < f.geometry.coordinates.length; i += 1) {
        result += distance(
          f.geometry.coordinates[i - 1][0],
          f.geometry.coordinates[i - 1][1],
          f.geometry.coordinates[i][0],
          f.geometry.coordinates[i][1],
        )
      }
      return result
    })
    .reduce((agg, val) => agg + val, 0)

  const metersToMiles = 1 / 1609.34
  if (unitSystem === "US") {
    return { length: (value * metersToMiles).toFixed(2), lengthUnit: "mi" }
  }
  return { length: value.toFixed(2), lengthUnit: "m" }
}

type PathProps = {
  path: FeatureCollection<LineString | Point>
}

export const PathMaxSpeed = ({ path }: PathProps): ReactElement => {
  const { maxSpeed, speedUnit } = FindMaxSpeed(path)
  return <Caption color="secondary">{`Max Speed: ${maxSpeed} ${speedUnit}`}</Caption>
}

export const PathLength = ({ path }: PathProps): ReactElement => {
  const { length, lengthUnit } = CalculateLength(path)
  return <Caption color="secondary">{`Length: ${length} ${lengthUnit}`}</Caption>
}

export const VerifyGeoJSONFeatureCollection = (fc: FeatureCollection): void => {
  const ajv = new Ajv()
  const validate = ajv.compile(GeoJsonFeatureCollectionSchema)
  validate(fc)

  if (validate.errors) {
    throw new Error(`Schema validation error:${ajv.errorsText(validate.errors, { dataVar: "" })}`)
  }
}

export const Base64ToFeatureCollection = (path: string): FeatureCollection<LineString | Point> => {
  return JSON.parse(atob(path))
}

export const FeatureCollectionToBase64 = (path: FeatureCollection<LineString | Point>): string => {
  return btoa(JSON.stringify(path))
}

// equatorial mean radius of Earth (in meters)
const R = 6378137

// The Haversine/great circle distance is the angular distance between two points on the surface of a sphere.
export function haversineDistanceMeters(startPoint: LatLng, endPoint: LatLng): number {
  const aLat = toRad(startPoint.latitude)
  const bLat = toRad(endPoint.latitude)
  const aLng = toRad(startPoint.longitude)
  const bLng = toRad(endPoint.longitude)

  const ht = hav(bLat - aLat) + Math.cos(aLat) * Math.cos(bLat) * hav(bLng - aLng)
  return 2 * R * Math.asin(Math.sqrt(ht))
}

function toRad(x: number): number {
  return (x * Math.PI) / 180.0
}
function hav(x: number): number {
  return Math.sin(x / 2) ** 2
}
