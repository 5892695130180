import { LayersList } from "@deck.gl/core/typed"
import { GeoJsonLayer } from "@deck.gl/layers/typed"
import { FeatureCollection } from "geojson"
import "mapbox-gl/dist/mapbox-gl.css"

// Typescript for forcing 4 numbers (rgba)
type Color = [number, number, number, number]

export const useGeoJsonLayers = (geojson?: FeatureCollection, color?: Color): LayersList => {
  if (!geojson) {
    return []
  }

  return [
    new GeoJsonLayer({
      id: "geojson-layer",
      data: geojson,
      lineWidthMinPixels: 4,
      lineCapRounded: true,
      lineJointRounded: true,
      getPointRadius: 0,
      getFillColor: (): [number, number, number, number] => {
        return [255, 255, 255, 255]
      },
      getLineColor: (feature): [number, number, number, number] => {
        const hex = feature.properties?.stroke
        if (color) {
          return color
        }
        if (hex) {
          const r = parseInt(hex.slice(1, 3), 16)
          const g = parseInt(hex.slice(3, 5), 16)
          const b = parseInt(hex.slice(5, 7), 16)
          return [r, g, b, 255]
        }
        return [0, 0, 0, 255]
      },
    }),
  ]
}
