// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/remote_interface_action_service.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ActionResponse, RemoteInterfaceAction } from "./remote_interface_action_pb.js";

/**
 * Request to create an action response
 *
 * @generated from message einride.rd_operator_interface.v1.CreateActionResponseRequest
 */
export class CreateActionResponseRequest extends Message<CreateActionResponseRequest> {
  /**
   * ID of the action response. NOT the id that the response is refering the status of
   *
   * @generated from field: string action_response_id = 1;
   */
  actionResponseId = "";

  /**
   * Action response containing information about the status of an action
   *
   * @generated from field: einride.rd_operator_interface.v1.ActionResponse action_response = 2;
   */
  actionResponse?: ActionResponse;

  constructor(data?: PartialMessage<CreateActionResponseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.CreateActionResponseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "action_response_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "action_response", kind: "message", T: ActionResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateActionResponseRequest {
    return new CreateActionResponseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateActionResponseRequest {
    return new CreateActionResponseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateActionResponseRequest {
    return new CreateActionResponseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateActionResponseRequest | PlainMessage<CreateActionResponseRequest> | undefined, b: CreateActionResponseRequest | PlainMessage<CreateActionResponseRequest> | undefined): boolean {
    return proto3.util.equals(CreateActionResponseRequest, a, b);
  }
}

/**
 * Request to CreateRemoteInterfaceActionRequest.
 *
 * @generated from message einride.rd_operator_interface.v1.CreateRemoteInterfaceActionRequest
 */
export class CreateRemoteInterfaceActionRequest extends Message<CreateRemoteInterfaceActionRequest> {
  /**
   * Remote Interface resource name.
   *
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * Action request.
   *
   * @generated from field: einride.rd_operator_interface.v1.RemoteInterfaceAction remote_interface_action = 2;
   */
  remoteInterfaceAction?: RemoteInterfaceAction;

  /**
   * ID of the request. Must be a UUID. Used to track the state of an action (e.g. error, pending or success)
   *
   * @generated from field: string remote_interface_action_id = 3;
   */
  remoteInterfaceActionId = "";

  constructor(data?: PartialMessage<CreateRemoteInterfaceActionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.CreateRemoteInterfaceActionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "remote_interface_action", kind: "message", T: RemoteInterfaceAction },
    { no: 3, name: "remote_interface_action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRemoteInterfaceActionRequest {
    return new CreateRemoteInterfaceActionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRemoteInterfaceActionRequest {
    return new CreateRemoteInterfaceActionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRemoteInterfaceActionRequest {
    return new CreateRemoteInterfaceActionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateRemoteInterfaceActionRequest | PlainMessage<CreateRemoteInterfaceActionRequest> | undefined, b: CreateRemoteInterfaceActionRequest | PlainMessage<CreateRemoteInterfaceActionRequest> | undefined): boolean {
    return proto3.util.equals(CreateRemoteInterfaceActionRequest, a, b);
  }
}

/**
 * A request to see the status of an action
 *
 * @generated from message einride.rd_operator_interface.v1.GetActionResponseRequest
 */
export class GetActionResponseRequest extends Message<GetActionResponseRequest> {
  /**
   * Resource name (request_id) of the action status to retrieve.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetActionResponseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.GetActionResponseRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetActionResponseRequest {
    return new GetActionResponseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetActionResponseRequest {
    return new GetActionResponseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetActionResponseRequest {
    return new GetActionResponseRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetActionResponseRequest | PlainMessage<GetActionResponseRequest> | undefined, b: GetActionResponseRequest | PlainMessage<GetActionResponseRequest> | undefined): boolean {
    return proto3.util.equals(GetActionResponseRequest, a, b);
  }
}

