// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/remote_interface_service.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateConnectionStatusRequest, GetRemoteInterfaceRequest, ListRemoteInterfacesRequest, ListRemoteInterfacesResponse } from "./remote_interface_service_pb.js";
import { ConnectionStatus, RemoteInterface } from "./remote_interface_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Remote interface service.
 *
 * @generated from service einride.rd_operator_interface.v1.RemoteInterfaceService
 */
export const RemoteInterfaceService = {
  typeName: "einride.rd_operator_interface.v1.RemoteInterfaceService",
  methods: {
    /**
     * Get a remote interface.
     *
     * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceService.GetRemoteInterface
     */
    getRemoteInterface: {
      name: "GetRemoteInterface",
      I: GetRemoteInterfaceRequest,
      O: RemoteInterface,
      kind: MethodKind.Unary,
    },
    /**
     * List remote interfaces.
     * (-- api-linter: core::0132::method-signature=disabled --)
     * (-- api-linter: core::0132::http-uri-parent=disabled --)
     *
     * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceService.ListRemoteInterfaces
     */
    listRemoteInterfaces: {
      name: "ListRemoteInterfaces",
      I: ListRemoteInterfacesRequest,
      O: ListRemoteInterfacesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Endpoint for connection status updates, usually from ri-manager
     *
     * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceService.CreateConnectionStatus
     */
    createConnectionStatus: {
      name: "CreateConnectionStatus",
      I: CreateConnectionStatusRequest,
      O: ConnectionStatus,
      kind: MethodKind.Unary,
    },
  }
} as const;

