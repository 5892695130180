import { useMutation } from "@tanstack/react-query"
import { Path } from "gen/einride/aet_path_service/v1"
import { useAPIClient } from "lib/api/hooks/useAPIClient"
import { Region } from "../client"

interface Props {
  region: Region
  path: Path
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useValidatePath = (props?: Props) => {
  const { region = "REGION_UNSPECIFIED" } = props || {}
  const newClient = useAPIClient(region)

  return useMutation(async ({ path }: Props) => {
    return newClient?.PathService.ValidatePath(path)
  })
}
