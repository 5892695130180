import { Banner, IconButton } from "@einride/ui"
import styled from "@emotion/styled"
import React, { createContext, useCallback, useContext, useMemo, useState } from "react"

type ToastContextType = {
  toasts: Map<string, InternalToast>
  toast: (input: Toast) => string
  dismiss: (id: string) => void
}

type Toast = {
  id?: string
  status: "success" | "fail"
  message: string
  keepCount?: boolean
}

type InternalToast = Toast & { count: number }

const ToastContext = createContext<ToastContextType>({
  toasts: new Map<string, InternalToast>(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toast: () => {
    return ""
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dismiss: () => {},
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<Map<string, InternalToast>>(new Map())

  const dismiss = useCallback((id: string): void => {
    setToasts((prevMap) => {
      const newMap = new Map(prevMap)
      newMap.delete(id)
      return newMap
    })
  }, [])

  const toast = useCallback(
    (input: Toast): string => {
      const id = input.id ?? input.message

      setToasts((prevMap) => {
        const newMap = new Map(prevMap)
        const keepCount = input.keepCount ?? true // Default keepCount to true

        if (newMap.has(id)) {
          const existingToast = newMap.get(id)!
          if (keepCount) {
            existingToast.count += 1
          }
          newMap.set(id, existingToast)
        } else {
          const newToast: InternalToast = { ...input, id, count: 1 }
          newMap.set(id, newToast)
          if (input.status === "success") {
            setTimeout(() => dismiss(id), 10 * 1000)
          }
        }
        return newMap
      })

      return id
    },
    [dismiss],
  )

  const value = useMemo(() => ({ toasts, toast, dismiss }), [toasts, toast, dismiss])

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useToasts = () => {
  return useContext(ToastContext)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Toasts = () => {
  const { toasts, dismiss } = useToasts()

  return (
    <ToastDiv>
      {Array.from(toasts.entries()).map(([id, toast]) => (
        <BannerDiv key={id} data-testid="banner">
          <Banner status={toast.status}>
            <DismissButtonDiv>
              <IconButton aria-label="dismiss" icon="xMark" onClick={() => dismiss(id)} />
            </DismissButtonDiv>
            <p data-testid="banner-text">
              {toast.message}
              {toast.count && toast.count > 1 && ` x${toast.count}`}
            </p>
          </Banner>
        </BannerDiv>
      ))}
    </ToastDiv>
  )
}

const DismissButtonDiv = styled.div`
  scale: 0.8;
  cursor: pointer;
`

const ToastDiv = styled.div`
  position: absolute;
  z-index: 1000;
  left: 50%;
  bottom: 0;
`

const BannerDiv = styled.div`
  position: relative;
  left: -50%;
  margin-bottom: 2vh;
`
