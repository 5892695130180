import { EinrideProvider, einrideTheme } from "@einride/ui"
import { ColorScheme } from "@einride/ui/dist/contexts/ColorSchemeProvider"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { App } from "App"
import { AuthContext } from "contexts/AuthContext"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { StyledRootLayout } from "views/RootLayout"

const container = document.getElementById("root")
if (!container) throw new Error("Failed to find the root container")
const root = createRoot(container)

const preferredColorTheme: ColorScheme | null = localStorage.getItem(
  "preferredColorTheme",
) as ColorScheme

const queryClient = new QueryClient()

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledRootLayout>
        <EinrideProvider colorMode={preferredColorTheme || "system"} theme={einrideTheme}>
          <AuthContext>
            <App />
          </AuthContext>
        </EinrideProvider>
      </StyledRootLayout>
    </QueryClientProvider>
  </StrictMode>,
)
