// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/remote_interface_action.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Path } from "./path_pb.js";

/**
 * Codes for actions in progress
 *
 * @generated from enum einride.rd_operator_interface.v1.ActionProgress
 */
export enum ActionProgress {
  /**
   * Default value
   *
   * @generated from enum value: ACTION_PROGRESS_UNSPECIFIED = 0;
   */
  ACTION_PROGRESS_UNSPECIFIED = 0,

  /**
   * Starting connection attempt
   *
   * @generated from enum value: STARTING_CONNECTION_ATTEMPT = 1;
   */
  STARTING_CONNECTION_ATTEMPT = 1,

  /**
   * Connecting to VPN
   *
   * @generated from enum value: CONNECTING_TO_VPN = 2;
   */
  CONNECTING_TO_VPN = 2,

  /**
   * Downloading software package
   *
   * @generated from enum value: DOWNLOADING_SOFTWARE_PACKAGE = 3;
   */
  DOWNLOADING_SOFTWARE_PACKAGE = 3,

  /**
   * Installing oden dome player
   *
   * @generated from enum value: INSTALLING_ODEN_DOME_PLAYER = 4;
   */
  INSTALLING_ODEN_DOME_PLAYER = 4,

  /**
   * Starting station software
   *
   * @generated from enum value: STARTING_STATION_SOFTWARE = 5;
   */
  STARTING_STATION_SOFTWARE = 5,

  /**
   * Connecting to vehicle
   *
   * @generated from enum value: CONNECTING_TO_VEHICLE = 6;
   */
  CONNECTING_TO_VEHICLE = 6,

  /**
   * Processing action request
   *
   * @generated from enum value: PROCESSING_ACTION = 7;
   */
  PROCESSING_ACTION = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(ActionProgress)
proto3.util.setEnumType(ActionProgress, "einride.rd_operator_interface.v1.ActionProgress", [
  { no: 0, name: "ACTION_PROGRESS_UNSPECIFIED" },
  { no: 1, name: "STARTING_CONNECTION_ATTEMPT" },
  { no: 2, name: "CONNECTING_TO_VPN" },
  { no: 3, name: "DOWNLOADING_SOFTWARE_PACKAGE" },
  { no: 4, name: "INSTALLING_ODEN_DOME_PLAYER" },
  { no: 5, name: "STARTING_STATION_SOFTWARE" },
  { no: 6, name: "CONNECTING_TO_VEHICLE" },
  { no: 7, name: "PROCESSING_ACTION" },
]);

/**
 * Codes for successfull actions
 *
 * @generated from enum einride.rd_operator_interface.v1.ActionOK
 */
export enum ActionOK {
  /**
   * Default value
   *
   * @generated from enum value: ACTION_OK_UNSPECIFIED = 0;
   */
  ACTION_OK_UNSPECIFIED = 0,

  /**
   * Connect successfull
   *
   * @generated from enum value: CONNECT_SUCCESS = 1;
   */
  CONNECT_SUCCESS = 1,

  /**
   * Disconnect successfull
   *
   * @generated from enum value: DISCONNECT_SUCCESS = 2;
   */
  DISCONNECT_SUCCESS = 2,

  /**
   * Path assign successs
   *
   * @generated from enum value: PATH_ASSIGN_SUCCESS = 3;
   */
  PATH_ASSIGN_SUCCESS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ActionOK)
proto3.util.setEnumType(ActionOK, "einride.rd_operator_interface.v1.ActionOK", [
  { no: 0, name: "ACTION_OK_UNSPECIFIED" },
  { no: 1, name: "CONNECT_SUCCESS" },
  { no: 2, name: "DISCONNECT_SUCCESS" },
  { no: 3, name: "PATH_ASSIGN_SUCCESS" },
]);

/**
 * Codes for failed actions
 *
 * @generated from enum einride.rd_operator_interface.v1.ActionError
 */
export enum ActionError {
  /**
   * Default value
   *
   * @generated from enum value: ACTION_ERROR_UNSPECIFIED = 0;
   */
  ACTION_ERROR_UNSPECIFIED = 0,

  /**
   * Action timeout
   *
   * @generated from enum value: TIMEOUT = 1;
   */
  TIMEOUT = 1,

  /**
   * VPN connection failed
   *
   * @generated from enum value: VPN_CONNECTION_FAILED = 2;
   */
  VPN_CONNECTION_FAILED = 2,

  /**
   * Software download failed
   *
   * @generated from enum value: SOFTWARE_DOWNLOAD_FAILED = 3;
   */
  SOFTWARE_DOWNLOAD_FAILED = 3,

  /**
   * Failed to install oden dome player
   *
   * @generated from enum value: ODEN_DOME_PLAYER_INSTALL_FAILED = 4;
   */
  ODEN_DOME_PLAYER_INSTALL_FAILED = 4,

  /**
   * Failed to start station software
   *
   * @generated from enum value: STATION_SOFTWARE_START_FAILED = 5;
   */
  STATION_SOFTWARE_START_FAILED = 5,

  /**
   * Station software process already running
   *
   * @generated from enum value: STATION_PROCESS_ALREADY_RUNNING = 6;
   */
  STATION_PROCESS_ALREADY_RUNNING = 6,

  /**
   * Disconnect attempt failed. Station is already disconnected.
   *
   * @generated from enum value: STATION_NOT_CONNECTED = 7;
   */
  STATION_NOT_CONNECTED = 7,

  /**
   * Incorrect requesting user
   *
   * @generated from enum value: INCORRECT_REQUESTING_USER = 8;
   */
  INCORRECT_REQUESTING_USER = 8,

  /**
   * Failed to stop action process
   *
   * @generated from enum value: STOP_STATION_PROCESSES_FAILED = 9;
   */
  STOP_STATION_PROCESSES_FAILED = 9,

  /**
   * A vehicle with the provided name was not found
   *
   * @generated from enum value: VEHICLE_NOT_FOUND = 10;
   */
  VEHICLE_NOT_FOUND = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(ActionError)
proto3.util.setEnumType(ActionError, "einride.rd_operator_interface.v1.ActionError", [
  { no: 0, name: "ACTION_ERROR_UNSPECIFIED" },
  { no: 1, name: "TIMEOUT" },
  { no: 2, name: "VPN_CONNECTION_FAILED" },
  { no: 3, name: "SOFTWARE_DOWNLOAD_FAILED" },
  { no: 4, name: "ODEN_DOME_PLAYER_INSTALL_FAILED" },
  { no: 5, name: "STATION_SOFTWARE_START_FAILED" },
  { no: 6, name: "STATION_PROCESS_ALREADY_RUNNING" },
  { no: 7, name: "STATION_NOT_CONNECTED" },
  { no: 8, name: "INCORRECT_REQUESTING_USER" },
  { no: 9, name: "STOP_STATION_PROCESSES_FAILED" },
  { no: 10, name: "VEHICLE_NOT_FOUND" },
]);

/**
 * RemoteInterfaceAction is used for sending action requests to the RI.
 *
 * @generated from message einride.rd_operator_interface.v1.RemoteInterfaceAction
 */
export class RemoteInterfaceAction extends Message<RemoteInterfaceAction> {
  /**
   * Pattern: remoteInterfaces/{remoteInterface}, where {remoteInterface} is a base32-encoded UUID.
   *
   * @generated from field: string remote_interface = 1;
   */
  remoteInterface = "";

  /**
   * Pattern: vehicles/{vehicleId}, where {vehicleId} is a base32-encoded UUID.
   *
   * @generated from field: string vehicle = 2;
   */
  vehicle = "";

  /**
   * The requesting user field contains the UID of the user requesting the action. The field is used
   * to ensure only the user that requested the initial action is able to execute new actions in the current session.
   *
   * @generated from field: string requesting_user = 3;
   */
  requestingUser = "";

  /**
   * @generated from oneof einride.rd_operator_interface.v1.RemoteInterfaceAction.action
   */
  action: {
    /**
     * Request to establish a connection between RI and AV.
     *
     * @generated from field: einride.rd_operator_interface.v1.RemoteInterfaceAction.ConnectAction connect = 4;
     */
    value: RemoteInterfaceAction_ConnectAction;
    case: "connect";
  } | {
    /**
     * Request to terminate a connection between RI and AV.
     *
     * @generated from field: einride.rd_operator_interface.v1.RemoteInterfaceAction.DisconnectAction disconnect = 5;
     */
    value: RemoteInterfaceAction_DisconnectAction;
    case: "disconnect";
  } | {
    /**
     * Request to assign a path to the connected AV.
     *
     * @generated from field: einride.rd_operator_interface.v1.RemoteInterfaceAction.AssignPathAction assign_path = 6;
     */
    value: RemoteInterfaceAction_AssignPathAction;
    case: "assignPath";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<RemoteInterfaceAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.RemoteInterfaceAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "remote_interface", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "vehicle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "requesting_user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "connect", kind: "message", T: RemoteInterfaceAction_ConnectAction, oneof: "action" },
    { no: 5, name: "disconnect", kind: "message", T: RemoteInterfaceAction_DisconnectAction, oneof: "action" },
    { no: 6, name: "assign_path", kind: "message", T: RemoteInterfaceAction_AssignPathAction, oneof: "action" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoteInterfaceAction {
    return new RemoteInterfaceAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoteInterfaceAction {
    return new RemoteInterfaceAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoteInterfaceAction {
    return new RemoteInterfaceAction().fromJsonString(jsonString, options);
  }

  static equals(a: RemoteInterfaceAction | PlainMessage<RemoteInterfaceAction> | undefined, b: RemoteInterfaceAction | PlainMessage<RemoteInterfaceAction> | undefined): boolean {
    return proto3.util.equals(RemoteInterfaceAction, a, b);
  }
}

/**
 * Request to establish a connection between RI and AV.
 *
 * @generated from message einride.rd_operator_interface.v1.RemoteInterfaceAction.ConnectAction
 */
export class RemoteInterfaceAction_ConnectAction extends Message<RemoteInterfaceAction_ConnectAction> {
  /**
   * Remote operation sw version on vehicle.
   *
   * @generated from field: string version = 1;
   */
  version = "";

  constructor(data?: PartialMessage<RemoteInterfaceAction_ConnectAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.RemoteInterfaceAction.ConnectAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoteInterfaceAction_ConnectAction {
    return new RemoteInterfaceAction_ConnectAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoteInterfaceAction_ConnectAction {
    return new RemoteInterfaceAction_ConnectAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoteInterfaceAction_ConnectAction {
    return new RemoteInterfaceAction_ConnectAction().fromJsonString(jsonString, options);
  }

  static equals(a: RemoteInterfaceAction_ConnectAction | PlainMessage<RemoteInterfaceAction_ConnectAction> | undefined, b: RemoteInterfaceAction_ConnectAction | PlainMessage<RemoteInterfaceAction_ConnectAction> | undefined): boolean {
    return proto3.util.equals(RemoteInterfaceAction_ConnectAction, a, b);
  }
}

/**
 * Request to terminate a connection between RI and AV.
 *
 * @generated from message einride.rd_operator_interface.v1.RemoteInterfaceAction.DisconnectAction
 */
export class RemoteInterfaceAction_DisconnectAction extends Message<RemoteInterfaceAction_DisconnectAction> {
  constructor(data?: PartialMessage<RemoteInterfaceAction_DisconnectAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.RemoteInterfaceAction.DisconnectAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoteInterfaceAction_DisconnectAction {
    return new RemoteInterfaceAction_DisconnectAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoteInterfaceAction_DisconnectAction {
    return new RemoteInterfaceAction_DisconnectAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoteInterfaceAction_DisconnectAction {
    return new RemoteInterfaceAction_DisconnectAction().fromJsonString(jsonString, options);
  }

  static equals(a: RemoteInterfaceAction_DisconnectAction | PlainMessage<RemoteInterfaceAction_DisconnectAction> | undefined, b: RemoteInterfaceAction_DisconnectAction | PlainMessage<RemoteInterfaceAction_DisconnectAction> | undefined): boolean {
    return proto3.util.equals(RemoteInterfaceAction_DisconnectAction, a, b);
  }
}

/**
 * Request to assign a path to the connected AV.
 *
 * @generated from message einride.rd_operator_interface.v1.RemoteInterfaceAction.AssignPathAction
 */
export class RemoteInterfaceAction_AssignPathAction extends Message<RemoteInterfaceAction_AssignPathAction> {
  /**
   * The path that is to be requested to be assigned to the AV.
   *
   * @generated from field: einride.rd_operator_interface.v1.Path path = 1;
   */
  path?: Path;

  constructor(data?: PartialMessage<RemoteInterfaceAction_AssignPathAction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.RemoteInterfaceAction.AssignPathAction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "path", kind: "message", T: Path },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoteInterfaceAction_AssignPathAction {
    return new RemoteInterfaceAction_AssignPathAction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoteInterfaceAction_AssignPathAction {
    return new RemoteInterfaceAction_AssignPathAction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoteInterfaceAction_AssignPathAction {
    return new RemoteInterfaceAction_AssignPathAction().fromJsonString(jsonString, options);
  }

  static equals(a: RemoteInterfaceAction_AssignPathAction | PlainMessage<RemoteInterfaceAction_AssignPathAction> | undefined, b: RemoteInterfaceAction_AssignPathAction | PlainMessage<RemoteInterfaceAction_AssignPathAction> | undefined): boolean {
    return proto3.util.equals(RemoteInterfaceAction_AssignPathAction, a, b);
  }
}

/**
 * Response sent by oden-supervisor to update the frontend about the status of its current operation
 * e.g. the status of the connect
 *
 * @generated from message einride.rd_operator_interface.v1.ActionResponse
 */
export class ActionResponse extends Message<ActionResponse> {
  /**
   * Pattern: actionResponses/{actionResponse}, where {actionResponse} is a UUID.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Timestamp when the action was sent
   *
   * @generated from field: google.protobuf.Timestamp action_time = 2;
   */
  actionTime?: Timestamp;

  /**
   * The status of the action
   *
   * @generated from oneof einride.rd_operator_interface.v1.ActionResponse.status
   */
  status: {
    /**
     * If the action is successfull
     *
     * @generated from field: einride.rd_operator_interface.v1.ActionOK ok = 3;
     */
    value: ActionOK;
    case: "ok";
  } | {
    /**
     * If the action has encountered an error
     *
     * @generated from field: einride.rd_operator_interface.v1.ActionError error = 4;
     */
    value: ActionError;
    case: "error";
  } | {
    /**
     * The action is pending
     *
     * @generated from field: einride.rd_operator_interface.v1.ActionProgress progress = 5;
     */
    value: ActionProgress;
    case: "progress";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ActionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.ActionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "action_time", kind: "message", T: Timestamp },
    { no: 3, name: "ok", kind: "enum", T: proto3.getEnumType(ActionOK), oneof: "status" },
    { no: 4, name: "error", kind: "enum", T: proto3.getEnumType(ActionError), oneof: "status" },
    { no: 5, name: "progress", kind: "enum", T: proto3.getEnumType(ActionProgress), oneof: "status" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActionResponse {
    return new ActionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActionResponse {
    return new ActionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActionResponse {
    return new ActionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ActionResponse | PlainMessage<ActionResponse> | undefined, b: ActionResponse | PlainMessage<ActionResponse> | undefined): boolean {
    return proto3.util.equals(ActionResponse, a, b);
  }
}

