import { Icon, Table, TableSkeleton, Tbody, Td, Th, Thead, Tr } from "@einride/ui"
import styled from "@emotion/styled"
import { RemoteInterface as RemoteInterfaceProto } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { useMemo, useState } from "react"
import { RemoteInterface } from "./RemoteInterface"

const StyledTable = styled(Table)`
  @media (max-width: 530px) {
    font-size: 12px;
    table-layout: fixed;
    width: 100%;
  }
`

type SortColumn = Pick<RemoteInterfaceProto, "name" | "displayName" | "location">

export const RemoteInterfaceList: React.FC<{
  remoteInterfaces: RemoteInterfaceProto[]
  isLoading: boolean
}> = ({ remoteInterfaces, isLoading }) => {
  const [sortingOrderAsc, setSortingOrderAsc] = useState<boolean>(true)
  const [sortedColumn, setSortedColumn] = useState<keyof SortColumn>("displayName")

  const handleSort = (column: keyof SortColumn): void => {
    if (column === sortedColumn) {
      setSortingOrderAsc(!sortingOrderAsc)
    } else {
      setSortingOrderAsc(true)
    }
    setSortedColumn(column)
  }

  useMemo(() => {
    remoteInterfaces.sort((a, b): number => {
      let [x, y] = [a, b]
      if (!sortingOrderAsc) {
        // flip arguments if we're sorting in reverse
        ;[x, y] = [b, a]
      }

      return x[sortedColumn].localeCompare(y[sortedColumn], undefined, { numeric: true })
    })
  }, [remoteInterfaces, sortingOrderAsc, sortedColumn])

  const th = (
    <Thead>
      <Tr color="secondary">
        <Th style={{ cursor: "pointer" }} scope="col" onClick={() => handleSort("displayName")}>
          Station
          <Icon
            style={{ paddingLeft: "0.5em", paddingRight: 0 }}
            name={sortingOrderAsc ? "chevronUp" : "chevronDown"}
            hidden={sortedColumn !== "displayName"}
          />
        </Th>
        <Th style={{ cursor: "pointer" }} scope="col" onClick={() => handleSort("location")}>
          Location
          <Icon
            style={{ paddingLeft: "0.5em", paddingRight: 0 }}
            name={sortingOrderAsc ? "chevronUp" : "chevronDown"}
            hidden={sortedColumn !== "location"}
          />
        </Th>
        <Th scope="col">Connected</Th>
        <Th scope="col">Status</Th>
      </Tr>
    </Thead>
  )

  return remoteInterfaces.length === 0 && isLoading ? (
    <TableSkeleton columns={4} rows={5} thead={th} />
  ) : (
    <StyledTable>
      {th}
      <Tbody>
        {remoteInterfaces.length === 0 ? (
          <Tr>
            <Td scope="row"> No stations found </Td>
          </Tr>
        ) : (
          remoteInterfaces.map((remoteInterface) => (
            <RemoteInterface
              key={remoteInterface.name}
              name={remoteInterface.name}
              displayName={remoteInterface.displayName}
              location={remoteInterface.location}
              status={remoteInterface.status}
            />
          ))
        )}
      </Tbody>
    </StyledTable>
  )
}
