import { useDisclosure } from "@einride/hooks"
import { Alert, Box, Icon, SecondaryButton } from "@einride/ui"
import styled from "@emotion/styled"
import { Cog } from "@emotion-icons/boxicons-solid"
import { TopRightFlexDiv } from "components/FlexDiv"
import { Logout } from "components/LogoutButton"
import { MobileSearchBar } from "components/MobileSearchBar"
import { SettingsSheet } from "components/SettingsSheet"
import { StyledTitle1 } from "components/StyledTitle1"
import FuzzySearch from "fuzzy-search"
import { RemoteInterface } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { useListRemoteInterfaces } from "lib/api/hooks/useListRemoteInterfaces"
import { useEffect, useState } from "react"
import { RemoteInterfaceList } from "./components/RemoteInterfaceList"

export const StyledSecondaryButton = styled(SecondaryButton)`
  @media (max-width: 530px) {
    font-size: 11px;
  }
`

export const StationView: React.FC = () => {
  const [stations, setStations] = useState<RemoteInterface[] | undefined>(undefined)

  const { isOpen: settingsIsOpen, handlers: settingsHandlers } = useDisclosure(false)
  const { isOpen: alertIsOpen, handlers: alertHandlers } = useDisclosure(false)
  const [searchTerm, setSearchTerm] = useState("")
  const searcher = new FuzzySearch(stations || [], ["location", "displayName"])
  const filteredStations: RemoteInterface[] = searcher.search(searchTerm)

  const { data: euData, isLoading: euIsLoading } = useListRemoteInterfaces({
    region: "EU",
    pageSize: 1000,
  })
  const { data: usData, isLoading: usIsLoading } = useListRemoteInterfaces({
    region: "US",
    pageSize: 1000,
  })

  useEffect(() => {
    setStations(euData.concat(usData))
  }, [euData, usData])

  const handleSearchChange = (searchString: string): void => {
    setSearchTerm(searchString)
  }

  return (
    <Box display="flex">
      <TopRightFlexDiv className="stationView">
        <StyledSecondaryButton rightIcon={<Icon name="warning" />} onClick={alertHandlers.open}>
          Using 24.1 or below?
        </StyledSecondaryButton>
        <StyledSecondaryButton onClick={settingsHandlers.open} rightIcon={<Cog />}>
          Settings
        </StyledSecondaryButton>
        <Logout>Log out</Logout>
      </TopRightFlexDiv>
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        padding="sm"
        height="100vh"
        width="100vw"
      >
        <StyledTitle1 className="extra">Select station</StyledTitle1>
        <Box padding="sm" height="100vh" width="100%">
          <MobileSearchBar
            wrapperProps={{ width: "100%" }}
            aria-label="searchStation"
            placeholder="Search stations"
            value={searchTerm}
            onInputChange={handleSearchChange}
          />
          <RemoteInterfaceList
            remoteInterfaces={filteredStations}
            isLoading={usIsLoading || euIsLoading}
          />
        </Box>
      </Box>
      <SettingsSheet handlers={settingsHandlers} isOpen={settingsIsOpen} />
      <Alert
        closeHandler={alertHandlers.close}
        isOpen={alertIsOpen}
        title="Select Operator Interface Version"
        description="You are currently using the v2 version of the operator interface,
         this version is only usuable with release 25 or later.
         If you are using an earlier version please switch to operator interface v1."
        primaryAction={{
          children: "Take me to v1",
          onClick: () => {
            window.location.href = "https://web.ro.einride.systems/"
          },
        }}
        secondaryAction={{ children: "Continue using v2", onClick: alertHandlers.close }}
      />
    </Box>
  )
}
