import { LayersList } from "@deck.gl/core/typed"
import { IconLayer } from "@deck.gl/layers/typed"
import { haversineDistanceMeters } from "components/PathComponents"
import { StreamVehicleLiveDataResponse } from "gen/einride/rd_operator_interface/v1/vehicle_live_pb"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import { LatLng } from "gen/google/type/latlng_pb"
import moment from "moment/moment"
import { useEffect, useMemo, useState } from "react"
import "mapbox-gl/dist/mapbox-gl.css"
import aetDefault from "../assets/aet.svg"
import aetWarning from "../assets/aetWarning.svg"

// To avoid a jumpy vehicle icon a minimum movement distance is set.
// Any position change smaller than this will not be rendered.
const minimumMovementDistMeters = 0.1

export const useVehiclePositionLayers = (
  vehicle?: Vehicle,
  vehicleLiveData?: StreamVehicleLiveDataResponse,
): { vehiclePositionLayers: LayersList; vehicleIsLive: boolean } => {
  const [pos, setPos] = useState<LatLng>(
    new LatLng({
      longitude: 0,
      latitude: 0,
    }),
  )

  useEffect(() => {
    setPos(
      new LatLng({
        longitude: vehicle?.state?.lastSeenGnss?.longitude,
        latitude: vehicle?.state?.lastSeenGnss?.latitude,
      }),
    )
  }, [vehicle?.state?.lastSeenGnss])

  const vehicleData = useMemo(() => {
    const getPos = (): LatLng => {
      if (!vehicleLiveData) {
        return pos
      }
      const newPos: LatLng = new LatLng({
        latitude: vehicleLiveData.position?.coordinate?.latitude,
        longitude: vehicleLiveData.position?.coordinate?.longitude,
      })
      const distanceInMeters = haversineDistanceMeters(pos, newPos)
      if (
        distanceInMeters > minimumMovementDistMeters &&
        newPos.longitude !== 0 &&
        newPos.latitude !== 0
      ) {
        setPos(newPos)
        return newPos
      }
      return pos
    }

    return { ...vehicleLiveData, ...{ vehiclePosition: getPos() } }
  }, [vehicleLiveData, pos])

  if (!vehicleLiveData) {
    return { vehiclePositionLayers: [], vehicleIsLive: false }
  }

  // Consider vehicle live if update time is less than 5 min old and position is not 0,0.
  const vehicleIsLive = (data: StreamVehicleLiveDataResponse, position: LatLng): boolean => {
    return (
      moment(new Date(data.position?.updateTime?.toDate() || "")).isAfter(
        moment().subtract(5, "minutes"),
      ) &&
      position.latitude !== 0 &&
      position.longitude !== 0
    )
  }

  return {
    vehiclePositionLayers: [
      new IconLayer({
        visible: pos.latitude !== 0 && pos.longitude !== 0,
        id: "vehicle",
        data: [vehicleData],
        getPosition: (data) => [data.vehiclePosition.longitude, data.vehiclePosition.latitude],
        getAngle: (data) => ((data.position?.heading || 0) * 180) / Math.PI,
        getIcon: (data) => {
          return {
            url: vehicleIsLive(data, pos) ? aetDefault : aetWarning,
            width: 160 / 2,
            height: 110 / 2,
          }
        },
        sizeUnits: "meters",
        getSize: 7,
        sizeMinPixels: 32,
        pickable: true,
      }),
    ],
    vehicleIsLive: vehicleIsLive(vehicleLiveData, pos),
  }
}
