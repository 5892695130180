// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/vehicle_live.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { LatLng } from "../../../google/type/latlng_pb.js";

/**
 * Response from StreamVehicleLiveDataResponse
 *
 * @generated from message einride.rd_operator_interface.v1.StreamVehicleLiveDataResponse
 */
export class StreamVehicleLiveDataResponse extends Message<StreamVehicleLiveDataResponse> {
  /**
   * Position information of the vehicle.
   *
   * @generated from field: einride.rd_operator_interface.v1.VehiclePosition position = 1;
   */
  position?: VehiclePosition;

  /**
   * Primary (Perception) Objects surrounding the vehicle.
   *
   * @generated from field: einride.rd_operator_interface.v1.Objects primary_objects = 2;
   */
  primaryObjects?: Objects;

  /**
   * Secondary (AEB) Objects surrounding the vehicle.
   *
   * @generated from field: einride.rd_operator_interface.v1.Objects secondary_objects = 3;
   */
  secondaryObjects?: Objects;

  /**
   * Battery level in percent.
   *
   * @generated from field: float battery = 4;
   */
  battery = 0;

  /**
   * Base64 encoded geojson path that the vehicle is currently following
   *
   * @generated from field: bytes path = 5;
   */
  path = new Uint8Array(0);

  /**
   * Base64 encoded geojson trajectory that the vehicle is currently following
   *
   * @generated from field: bytes trajectory = 6;
   */
  trajectory = new Uint8Array(0);

  constructor(data?: PartialMessage<StreamVehicleLiveDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.StreamVehicleLiveDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "position", kind: "message", T: VehiclePosition },
    { no: 2, name: "primary_objects", kind: "message", T: Objects },
    { no: 3, name: "secondary_objects", kind: "message", T: Objects },
    { no: 4, name: "battery", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "path", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 6, name: "trajectory", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamVehicleLiveDataResponse {
    return new StreamVehicleLiveDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamVehicleLiveDataResponse {
    return new StreamVehicleLiveDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamVehicleLiveDataResponse {
    return new StreamVehicleLiveDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StreamVehicleLiveDataResponse | PlainMessage<StreamVehicleLiveDataResponse> | undefined, b: StreamVehicleLiveDataResponse | PlainMessage<StreamVehicleLiveDataResponse> | undefined): boolean {
    return proto3.util.equals(StreamVehicleLiveDataResponse, a, b);
  }
}

/**
 * Source: perception.v1.Egostate and perception.v1.UTMToLocalTransform.
 *
 * @generated from message einride.rd_operator_interface.v1.VehiclePosition
 */
export class VehiclePosition extends Message<VehiclePosition> {
  /**
   * LatLng coordinate of vehicle.
   *
   * @generated from field: google.type.LatLng coordinate = 1;
   */
  coordinate?: LatLng;

  /**
   * Update time.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 2;
   */
  updateTime?: Timestamp;

  /**
   * Heading in radians. Note: defaults to 0 when missing data.
   *
   * @generated from field: float heading = 3;
   */
  heading = 0;

  constructor(data?: PartialMessage<VehiclePosition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.VehiclePosition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "coordinate", kind: "message", T: LatLng },
    { no: 2, name: "update_time", kind: "message", T: Timestamp },
    { no: 3, name: "heading", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VehiclePosition {
    return new VehiclePosition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VehiclePosition {
    return new VehiclePosition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VehiclePosition {
    return new VehiclePosition().fromJsonString(jsonString, options);
  }

  static equals(a: VehiclePosition | PlainMessage<VehiclePosition> | undefined, b: VehiclePosition | PlainMessage<VehiclePosition> | undefined): boolean {
    return proto3.util.equals(VehiclePosition, a, b);
  }
}

/**
 * Vector/3D point structure.
 *
 * @generated from message einride.rd_operator_interface.v1.Vector
 */
export class Vector extends Message<Vector> {
  /**
   * x (forward/back).
   *
   * @generated from field: float x = 1;
   */
  x = 0;

  /**
   * y (left/right).
   *
   * @generated from field: float y = 2;
   */
  y = 0;

  /**
   * y (up/down).
   *
   * @generated from field: float z = 3;
   */
  z = 0;

  constructor(data?: PartialMessage<Vector>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.Vector";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "z", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vector {
    return new Vector().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vector {
    return new Vector().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vector {
    return new Vector().fromJsonString(jsonString, options);
  }

  static equals(a: Vector | PlainMessage<Vector> | undefined, b: Vector | PlainMessage<Vector> | undefined): boolean {
    return proto3.util.equals(Vector, a, b);
  }
}

/**
 * Polygon definition, repeated 3D points.
 *
 * @generated from message einride.rd_operator_interface.v1.Polygon
 */
export class Polygon extends Message<Polygon> {
  /**
   * A list of vertices in meter.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.Vector vertices_metre = 1;
   */
  verticesMetre: Vector[] = [];

  constructor(data?: PartialMessage<Polygon>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.Polygon";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vertices_metre", kind: "message", T: Vector, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Polygon {
    return new Polygon().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Polygon {
    return new Polygon().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Polygon {
    return new Polygon().fromJsonString(jsonString, options);
  }

  static equals(a: Polygon | PlainMessage<Polygon> | undefined, b: Polygon | PlainMessage<Polygon> | undefined): boolean {
    return proto3.util.equals(Polygon, a, b);
  }
}

/**
 * Objects definition, repeated object polygons.
 *
 * @generated from message einride.rd_operator_interface.v1.Objects
 */
export class Objects extends Message<Objects> {
  /**
   * Time the object list was published.
   *
   * @generated from field: google.protobuf.Timestamp publish_time = 1;
   */
  publishTime?: Timestamp;

  /**
   * Detected objects, expressed in base frame.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.Objects.Object objects_base = 3;
   */
  objectsBase: Objects_Object[] = [];

  constructor(data?: PartialMessage<Objects>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.Objects";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "publish_time", kind: "message", T: Timestamp },
    { no: 3, name: "objects_base", kind: "message", T: Objects_Object, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Objects {
    return new Objects().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Objects {
    return new Objects().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Objects {
    return new Objects().fromJsonString(jsonString, options);
  }

  static equals(a: Objects | PlainMessage<Objects> | undefined, b: Objects | PlainMessage<Objects> | undefined): boolean {
    return proto3.util.equals(Objects, a, b);
  }
}

/**
 * Object definition.
 *
 * @generated from message einride.rd_operator_interface.v1.Objects.Object
 */
export class Objects_Object extends Message<Objects_Object> {
  /**
   * Polygon enveloping the object in XY-plane, expressed in base frame.
   * Polygons are concave and does not self-intersect.
   * Z-dimension is unused (always set to 0).
   *
   * @generated from field: einride.rd_operator_interface.v1.Polygon polygon = 1;
   */
  polygon?: Polygon;

  constructor(data?: PartialMessage<Objects_Object>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.Objects.Object";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "polygon", kind: "message", T: Polygon },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Objects_Object {
    return new Objects_Object().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Objects_Object {
    return new Objects_Object().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Objects_Object {
    return new Objects_Object().fromJsonString(jsonString, options);
  }

  static equals(a: Objects_Object | PlainMessage<Objects_Object> | undefined, b: Objects_Object | PlainMessage<Objects_Object> | undefined): boolean {
    return proto3.util.equals(Objects_Object, a, b);
  }
}

