// Code generated by protoc-gen-typescript-http. DO NOT EDIT.
/* eslint-disable camelcase */
// @ts-nocheck

// Path represents a path on WGS84-format.
export type Path = {
  // Path resource name
  // Pattern: sites/{site}/paths/{path}, where {path} is a base32-encoded, lowercase, UUID.
  //
  // Behaviors: 8
  name: string | undefined;
  // Display name for the path.
  //
  // Behaviors: REQUIRED
  displayName: string | undefined;
  // Geojson path, the format must correspond to the WSG84 format that the 'ad' UnmarshalGeoJSON function produces.
  // Said function can be found here: https://github.com/einride/ad/blob/master/pkg/geojsonpaths/unmarshal.go#L50-L95
  //
  // Behaviors: REQUIRED
  path: string | undefined;
  // Version corresponds to the WSG84 proto version used when creating the path. Available versions can be found here:
  // https://github.com/einride/proto-aet/tree/master/src/ad
  //
  // Behaviors: REQUIRED
  version: string | undefined;
  // Optional sub_site field used to filter paths. E.g. 'flexzone-z1' or 'garageplan'. This field is only used for
  // filtering and is not validated against the parent site in any way.
  //
  // Behaviors: OPTIONAL
  subSite: string | undefined;
};

// A Site contains paths that belong to it.
export type Site = {
  // Pattern: sites/{site}, where {site} is a base32-encoded, lowercase, UUID.
  //
  // Behaviors: 8
  name: string | undefined;
  // Display name for the site.
  //
  // Behaviors: REQUIRED
  displayName: string | undefined;
  // 2 letter CLDR region code of the country/region of the site.
  // See AIP 143 https://google.aip.dev/143 for further details and links to definitions.
  // See https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry for full list.
  // Example: "SE" for Sweden.
  //
  // Behaviors: REQUIRED
  regionCode: string | undefined;
};

// Specifies a view of the path resource.
export type PathView =
  // The default / unset value.
  // The API will default to the FULL view.
  | "PATH_VIEW_UNSPECIFIED"
  // Include everything.
  // This is the default value (for both ListBooks and GetBook).
  | "PATH_VIEW_FULL"
  // Include basic metadata about the path, but not the actual geojson.
  | "PATH_VIEW_BASIC";
// Request to GetPath.
export type GetPathRequest = {
  // Resource name of the path to retrieve.
  //
  // Behaviors: REQUIRED
  name: string | undefined;
};

// Request to ListPaths.
export type ListPathsRequest = {
  // Resource name of the parent site that the path lives under.
  //
  // Behaviors: REQUIRED
  parent: string | undefined;
  // Requested page size. Server may return fewer results than requested.
  // If unspecified, at most 100 results will be returned.
  // The maximum value is 1000.
  //
  // Behaviors: OPTIONAL
  pageSize: number | undefined;
  // A token identifying a page of results the server should return.
  // Typically, this is the value of `next_page_token` returned from
  // the previous call to `ListPaths` method.
  //
  // Behaviors: OPTIONAL
  pageToken: string | undefined;
  // Specify a view of the data, useful to trim the size of the full
  // payload.
  // Defaults to the full view if missing.
  //
  // Behaviors: OPTIONAL
  view: PathView | undefined;
};

// Response from ListPaths.
export type ListPathsResponse = {
  // The list of paths.
  paths: Path[] | undefined;
  // A token to retrieve next page of results. Pass this value in the `page_token`
  // field in the subsequent call to `ListPaths` method to retrieve the next
  // page of results.
  nextPageToken: string | undefined;
};

// Request to GetSite.
export type GetSiteRequest = {
  // Resource name of the site to retrieve.
  //
  // Behaviors: REQUIRED
  name: string | undefined;
};

// Request to ListSites.
export type ListSitesRequest = {
  // Requested page size. Server may return fewer results than requested.
  // If unspecified, at most 100 results will be returned.
  // The maximum value is 1000.
  //
  // Behaviors: OPTIONAL
  pageSize: number | undefined;
  // A token identifying a page of results the server should return.
  // Typically, this is the value of `next_page_token` returned from
  // the previous call to `ListSites` method.
  //
  // Behaviors: OPTIONAL
  pageToken: string | undefined;
};

// Response from ListSites.
export type ListSitesResponse = {
  // The list of sites.
  sites: Site[] | undefined;
  // A token to retrieve next page of results. Pass this value in the `page_token`
  // field in the subsequent call to `ListSites` method to retrieve the next
  // page of results.
  nextPageToken: string | undefined;
};

// Service to manage Paths.
export interface PathService {
  // Get a path.
  GetPath(request: GetPathRequest): Promise<Path>;
  // List paths.
  ListPaths(request: ListPathsRequest): Promise<ListPathsResponse>;
  // Validate path.
  // Linting rule for RPC_REQUEST_STANDARD_NAME disabled as the rule is only defined as 'should' and not 'must'
  // in the AIPs. Here it makes most sense to just send the path instead of wrapping it. The specific rule is
  // 'Custom methods should take a request message matching the RPC name, with a Request suffix.'
  // https://google.aip.dev/136
  // buf:lint:ignore RPC_REQUEST_STANDARD_NAME
  ValidatePath(request: Path): Promise<Path>;
  // Get a site.
  GetSite(request: GetSiteRequest): Promise<Site>;
  // List sites.
  ListSites(request: ListSitesRequest): Promise<ListSitesResponse>;
}

type RequestType = {
  path: string;
  method: string;
  body: string | null;
};

type RequestHandler = (request: RequestType, meta: { service: string, method: string }) => Promise<unknown>;

export function createPathServiceClient(
  handler: RequestHandler
): PathService {
  return {
    GetPath(request) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.name) {
        throw new Error("missing required field request.name");
      }
      const path = `v1/${request.name}`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }, {
        service: "PathService",
        method: "GetPath",
      }) as Promise<Path>;
    },
    ListPaths(request) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.parent) {
        throw new Error("missing required field request.parent");
      }
      const path = `v1/${request.parent}/paths`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      if (request.pageSize) {
        queryParams.push(`pageSize=${encodeURIComponent(request.pageSize.toString())}`)
      }
      if (request.pageToken) {
        queryParams.push(`pageToken=${encodeURIComponent(request.pageToken.toString())}`)
      }
      if (request.view) {
        queryParams.push(`view=${encodeURIComponent(request.view.toString())}`)
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }, {
        service: "PathService",
        method: "ListPaths",
      }) as Promise<ListPathsResponse>;
    },
    ValidatePath(request) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `v1/path:validate`; // eslint-disable-line quotes
      const body = JSON.stringify(request);
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return handler({
        path: uri,
        method: "POST",
        body,
      }, {
        service: "PathService",
        method: "ValidatePath",
      }) as Promise<Path>;
    },
    GetSite(request) { // eslint-disable-line @typescript-eslint/no-unused-vars
      if (!request.name) {
        throw new Error("missing required field request.name");
      }
      const path = `v1/${request.name}`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }, {
        service: "PathService",
        method: "GetSite",
      }) as Promise<Site>;
    },
    ListSites(request) { // eslint-disable-line @typescript-eslint/no-unused-vars
      const path = `v1/sites`; // eslint-disable-line quotes
      const body = null;
      const queryParams: string[] = [];
      if (request.pageSize) {
        queryParams.push(`pageSize=${encodeURIComponent(request.pageSize.toString())}`)
      }
      if (request.pageToken) {
        queryParams.push(`pageToken=${encodeURIComponent(request.pageToken.toString())}`)
      }
      let uri = path;
      if (queryParams.length > 0) {
        uri += `?${queryParams.join("&")}`
      }
      return handler({
        path: uri,
        method: "GET",
        body,
      }, {
        service: "PathService",
        method: "ListSites",
      }) as Promise<ListSitesResponse>;
    },
  };
}

// @@protoc_insertion_point(typescript-http-eof)
