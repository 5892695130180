// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/vehicle_service.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Vehicle } from "./vehicle_pb.js";

/**
 * Request to GetVehicle.
 *
 * @generated from message einride.rd_operator_interface.v1.GetVehicleRequest
 */
export class GetVehicleRequest extends Message<GetVehicleRequest> {
  /**
   * Resource name of the vehicle to retrieve.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetVehicleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.GetVehicleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVehicleRequest {
    return new GetVehicleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVehicleRequest {
    return new GetVehicleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVehicleRequest {
    return new GetVehicleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVehicleRequest | PlainMessage<GetVehicleRequest> | undefined, b: GetVehicleRequest | PlainMessage<GetVehicleRequest> | undefined): boolean {
    return proto3.util.equals(GetVehicleRequest, a, b);
  }
}

/**
 * Request to ListVehicles.
 *
 * @generated from message einride.rd_operator_interface.v1.ListVehiclesRequest
 */
export class ListVehiclesRequest extends Message<ListVehiclesRequest> {
  /**
   * Requested page size. Server may return fewer results than requested.
   * If unspecified, at most 100 results will be returned.
   * The maximum value is 1000.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize = 0;

  /**
   * A token identifying a page of results the server should return.
   * Typically, this is the value of `next_page_token` returned from
   * the previous call to `ListVehicles` method.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListVehiclesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.ListVehiclesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListVehiclesRequest {
    return new ListVehiclesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListVehiclesRequest {
    return new ListVehiclesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListVehiclesRequest {
    return new ListVehiclesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListVehiclesRequest | PlainMessage<ListVehiclesRequest> | undefined, b: ListVehiclesRequest | PlainMessage<ListVehiclesRequest> | undefined): boolean {
    return proto3.util.equals(ListVehiclesRequest, a, b);
  }
}

/**
 * Response from ListVehicles.
 *
 * @generated from message einride.rd_operator_interface.v1.ListVehiclesResponse
 */
export class ListVehiclesResponse extends Message<ListVehiclesResponse> {
  /**
   * The list of ListVehicles.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.Vehicle vehicles = 1;
   */
  vehicles: Vehicle[] = [];

  /**
   * A token to retrieve next page of results. Pass this value in the `page_token`
   * field in the subsequent call to `ListVehicles` method to retrieve the next
   * page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListVehiclesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.ListVehiclesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vehicles", kind: "message", T: Vehicle, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListVehiclesResponse {
    return new ListVehiclesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListVehiclesResponse {
    return new ListVehiclesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListVehiclesResponse {
    return new ListVehiclesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListVehiclesResponse | PlainMessage<ListVehiclesResponse> | undefined, b: ListVehiclesResponse | PlainMessage<ListVehiclesResponse> | undefined): boolean {
    return proto3.util.equals(ListVehiclesResponse, a, b);
  }
}

/**
 * Request to StreamVehicleLiveDataResponse.
 *
 * @generated from message einride.rd_operator_interface.v1.StreamVehicleLiveDataRequest
 */
export class StreamVehicleLiveDataRequest extends Message<StreamVehicleLiveDataRequest> {
  /**
   * Resource name of the vehicle to retrieve.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<StreamVehicleLiveDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.StreamVehicleLiveDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamVehicleLiveDataRequest {
    return new StreamVehicleLiveDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamVehicleLiveDataRequest {
    return new StreamVehicleLiveDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamVehicleLiveDataRequest {
    return new StreamVehicleLiveDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StreamVehicleLiveDataRequest | PlainMessage<StreamVehicleLiveDataRequest> | undefined, b: StreamVehicleLiveDataRequest | PlainMessage<StreamVehicleLiveDataRequest> | undefined): boolean {
    return proto3.util.equals(StreamVehicleLiveDataRequest, a, b);
  }
}

