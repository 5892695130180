import { IconLayer } from "@deck.gl/layers/typed"
import { FeatureCollection, Position } from "geojson"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import aetDefault from "../assets/aet.svg"

const calculateDistance = ([lon1, lat1]: Position, [lon2, lat2]: Position): number => {
  const R = 6371000 // Radius of the Earth in meters
  const deltaLatitude = (lat1 * Math.PI) / 180
  const deltaLongitude = (lat2 * Math.PI) / 180
  const latitudeDifference = ((lat2 - lat1) * Math.PI) / 180
  const longitudeDifference = ((lon2 - lon1) * Math.PI) / 180

  const halfChordLength =
    Math.sin(latitudeDifference / 2) * Math.sin(latitudeDifference / 2) +
    Math.cos(deltaLatitude) *
      Math.cos(deltaLongitude) *
      Math.sin(longitudeDifference / 2) *
      Math.sin(longitudeDifference / 2)

  const angularDistance = 2 * Math.atan2(Math.sqrt(halfChordLength), Math.sqrt(1 - halfChordLength))

  return R * angularDistance // Distance in meters
}

function lngLatToMeters([lng, lat]: Position): Position {
  const x = (lng * 20037508.34) / 180
  const y =
    (Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180)) * (20037508.34 / 180)
  return [x, y]
}

type AnimatedPosition = {
  position: Position
  direction: "DIRECTION_FORWARD" | "DIRECTION_BACKWARD"
  maxSpeedMetresPerSecond: number
}

const extractPathCoordinates = (geojson?: FeatureCollection): AnimatedPosition[] => {
  if (!geojson) return []

  return geojson.features.flatMap((feature) => {
    if (feature.geometry.type === "LineString") {
      const direction = feature.properties?.direction ?? "DIRECTION_FORWARD"

      const maxSpeedMetresPerSecond = feature.properties?.maxSpeedMetresPerSecond
      // 10x the actual speed of the truck
      const adjustedMaxSpeedMetresPerSecond = maxSpeedMetresPerSecond
        ? maxSpeedMetresPerSecond * 10
        : 1

      return feature.geometry.coordinates.map((coordinate) => ({
        position: coordinate as Position,
        direction,
        maxSpeedMetresPerSecond: adjustedMaxSpeedMetresPerSecond,
      }))
    }
    return []
  })
}

export const useAnimatedPathLayer = (path?: FeatureCollection): IconLayer | null => {
  const pathCoordinates = useMemo(() => extractPathCoordinates(path), [path])

  // Precompute distances and durations between segments
  const segmentDurations = useMemo(() => {
    const durations = []
    for (let i = 0; i < pathCoordinates.length - 1; i += 1) {
      const distance = calculateDistance(
        pathCoordinates[i].position,
        pathCoordinates[i + 1].position,
      )
      const speed = pathCoordinates[i].maxSpeedMetresPerSecond
      durations.push(distance / speed)
    }
    return durations
  }, [pathCoordinates])

  const totalPathDuration = useMemo(() => {
    return segmentDurations.reduce((acc, duration) => acc + duration, 0)
  }, [segmentDurations])

  const [animationTime, setAnimationTime] = useState(0)
  const animationRef = useRef<number | null>(null)

  const animateMarker = useCallback(
    (startTime: number) => {
      const elapsed = (Date.now() - startTime) / 1000 // in seconds
      let accumulatedTime = 0

      if (elapsed >= totalPathDuration) {
        // Reset animation time to loop
        setAnimationTime(0)
        animationRef.current = window.requestAnimationFrame(() => animateMarker(Date.now()))
        return
      }

      for (let i = 0; i < segmentDurations.length; i += 1) {
        const segmentDuration = segmentDurations[i]
        accumulatedTime += segmentDuration

        if (elapsed < accumulatedTime) {
          const segmentProgress = (elapsed - (accumulatedTime - segmentDuration)) / segmentDuration
          setAnimationTime(i + segmentProgress)
          break
        }
      }

      animationRef.current = window.requestAnimationFrame(() => animateMarker(startTime))
    },
    [segmentDurations, totalPathDuration],
  )

  useEffect((): (() => void) | void => {
    if (pathCoordinates.length > 0) {
      const startTime = Date.now()
      animationRef.current = window.requestAnimationFrame(() => animateMarker(startTime))

      return () => {
        if (animationRef.current) {
          window.cancelAnimationFrame(animationRef.current)
        }
      }
    }

    return undefined
  }, [pathCoordinates, segmentDurations, totalPathDuration, animateMarker])

  if (!pathCoordinates || pathCoordinates.length < 1) {
    return null
  }

  const getMarkerPosition = (): Position => {
    const currentIndex = Math.floor(animationTime)
    const nextIndex = currentIndex + 1

    if (nextIndex >= pathCoordinates.length) {
      return pathCoordinates[0].position
    }

    const [x1, y1] = pathCoordinates[currentIndex].position
    const [x2, y2] = pathCoordinates[nextIndex].position

    const t = animationTime - currentIndex
    const interpolatedPosition: Position = [x1 + (x2 - x1) * t, y1 + (y2 - y1) * t]

    return interpolatedPosition
  }

  const getMarkerBearing = (): number => {
    const currentIndex = Math.floor(animationTime)
    const nextIndex = currentIndex + 1

    if (nextIndex >= pathCoordinates.length) {
      return 0
    }

    const [lon1, lat1] = pathCoordinates[currentIndex].position
    const [lon2, lat2] = pathCoordinates[nextIndex].position

    // Project coordinates to meters
    const [x1, y1] = lngLatToMeters([lon1, lat1])
    const [x2, y2] = lngLatToMeters([lon2, lat2])

    const dx = x2 - x1
    const dy = y2 - y1

    let angle = (Math.atan2(dy, dx) * 180) / Math.PI
    angle = (angle + 360) % 360

    // Adjust for backward direction
    if (pathCoordinates[currentIndex].direction === "DIRECTION_BACKWARD") {
      angle = (angle + 180) % 360
    }

    return angle
  }

  const iconLayer = new IconLayer({
    id: "icon-layer",
    data: [{}],
    // @ts-expect-error web.gl expects a Float32 array, but converting the numbers to Float32 array gives a "shaky" animation
    // It works better not converting
    getPosition: () => getMarkerPosition(),
    getAngle: () => getMarkerBearing(),
    getIcon: () => ({
      url: aetDefault,
      width: 160 / 2,
      height: 110 / 2,
    }),
    getColor: () => [255, 255, 255, 255 / 1.5],
    sizeUnits: "meters",
    getSize: 7,
    sizeMinPixels: 32,
    pickable: true,
  })

  return iconLayer
}
