// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/remote_interface.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * A RemoteInterface contains combined information about a RI's static attributes and if connected to an AV it also
 * contains information about what AV is is connected to.
 *
 * @generated from message einride.rd_operator_interface.v1.RemoteInterface
 */
export class RemoteInterface extends Message<RemoteInterface> {
  /**
   * Pattern: remoteInterfaces/{remoteInterface}, where {remoteInterface} is a base32-encoded UUID.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * Variant of the remote interface, e.g. Goodwood.
   *
   * @generated from field: string variant = 2;
   */
  variant = "";

  /**
   * Display name of the entity, e.g. "RI1000".
   *
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  /**
   * Location of the stations.
   *
   * @generated from field: string location = 4;
   */
  location = "";

  /**
   * Connection status of the station.
   *
   * @generated from field: einride.rd_operator_interface.v1.ConnectionStatus status = 5;
   */
  status?: ConnectionStatus;

  constructor(data?: PartialMessage<RemoteInterface>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.RemoteInterface";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "variant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "message", T: ConnectionStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoteInterface {
    return new RemoteInterface().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoteInterface {
    return new RemoteInterface().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoteInterface {
    return new RemoteInterface().fromJsonString(jsonString, options);
  }

  static equals(a: RemoteInterface | PlainMessage<RemoteInterface> | undefined, b: RemoteInterface | PlainMessage<RemoteInterface> | undefined): boolean {
    return proto3.util.equals(RemoteInterface, a, b);
  }
}

/**
 * Connection status of the station.
 *
 * @generated from message einride.rd_operator_interface.v1.ConnectionStatus
 */
export class ConnectionStatus extends Message<ConnectionStatus> {
  /**
   * Name of vehicle station is connected to.
   *
   * @generated from field: string connected_vehicle = 1;
   */
  connectedVehicle = "";

  /**
   * Name of the station reporting the connection status.
   *
   * @generated from field: string station = 2;
   */
  station = "";

  /**
   * Latest timestamp any connection status for the station was read.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;

  constructor(data?: PartialMessage<ConnectionStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.ConnectionStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connected_vehicle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "station", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectionStatus {
    return new ConnectionStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectionStatus {
    return new ConnectionStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectionStatus {
    return new ConnectionStatus().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectionStatus | PlainMessage<ConnectionStatus> | undefined, b: ConnectionStatus | PlainMessage<ConnectionStatus> | undefined): boolean {
    return proto3.util.equals(ConnectionStatus, a, b);
  }
}

