import { PathService, createPathServiceClient } from "gen/einride/aet_path_service/v1"
import { createRequestHandler } from "./requestHandler"

// Regions of data localization.
export type Region =
  // Unspecified region
  | "REGION_UNSPECIFIED"
  // European Union
  | "EU"
  // United States
  | "US"

interface RegionalServices {
  PathService: PathService
}

export const createRegionalAPIClient = (
  getToken: () => Promise<string> | undefined,
): {
  [Key in Region]: RegionalServices
} => {
  const euPathSvcRequestHandler = createRequestHandler(
    getToken,
    import.meta.env.VITE_PATH_SVC_BASE_URI_EU,
  )
  const usPathSvcRequestHandler = createRequestHandler(
    getToken,
    import.meta.env.VITE_PATH_SVC_BASE_URI_US,
  )

  const regionalServicesRoiService = (region: Region): RegionalServices => {
    const pathSvcRequestHandler =
      region === "US" ? usPathSvcRequestHandler : euPathSvcRequestHandler

    return {
      PathService: createPathServiceClient(pathSvcRequestHandler),
    }
  }

  return {
    EU: regionalServicesRoiService("EU"),
    US: regionalServicesRoiService("US"),
    REGION_UNSPECIFIED: regionalServicesRoiService("REGION_UNSPECIFIED"),
  }
}
