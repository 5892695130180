import { Box, Card, Icon, Logo, PrimaryButton, Stack, Title2, VerticalSpacing } from "@einride/ui"
import { useAuth } from "contexts/AuthContext"

export const LoginPage = (): React.JSX.Element => {
  const { signIn, loginState } = useAuth()

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh", minWidth: "100vw" }}
    >
      <Card background="secondary" style={{ minWidth: "30vw", width: "fit-content" }}>
        <VerticalSpacing size="sm" />
        <Logo size="lg" />
        <VerticalSpacing size="sm" />
        <Card background="secondary" borderRadius="sm">
          <VerticalSpacing size="md" />
          <Stack gap="md">
            <Title2>Operator Interface</Title2>
            <PrimaryButton
              onClick={signIn}
              rightIcon={<Icon name="arrowRight" />}
              isLoading={loginState === "pending"}
            >
              {loginState !== "pending" ? "Sign in with your Einride account" : "Logging in"}
            </PrimaryButton>
          </Stack>
        </Card>
      </Card>
    </Box>
  )
}
