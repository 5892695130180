import { Title1 } from "@einride/ui"
import styled from "@emotion/styled"

export const StyledTitle1 = styled(Title1)`
  @media (max-width: 530px) {
    font-size: 18px;
    padding-top: 1.3rem;
  }
  &.choosePath {
    font-size: 1.75rem;
  }
  &.extra {
    @media (max-width: 530px) {
      padding-top: 4rem;
      padding-left: 1.2rem;
    }
  }
`
