import { ToastProvider, Toasts } from "components/Toast"
import React from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import { ChoosePathView } from "views/choosePath/ChoosePathView"
import { NotFoundPage } from "views/NotFound"
import { RootLayout } from "views/RootLayout"
import { StationView } from "views/stationView/StationView"
import { VehicleChooserView } from "views/vehicleChooserView/VehicleChooserView"

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "*", element: <NotFoundPage /> },
      { path: "/", element: <Navigate to="/remoteInterfaces" /> },
      {
        path: "/remoteInterfaces",
        element: <StationView />,
      },
      {
        path: "/remoteInterfaces/:remoteInterface/vehicles",
        element: <VehicleChooserView />,
      },
      {
        path: "/remoteInterfaces/:remoteInterface/vehicles/:vehicle/drive",
        element: <ChoosePathView />,
      },
    ],
  },
])

export const App = (): React.JSX.Element => {
  return (
    <ToastProvider>
      <RouterProvider router={router} />
      <Toasts />
    </ToastProvider>
  )
}
