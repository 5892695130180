import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query"
import { ListSitesResponse, Site } from "gen/einride/aet_path_service/v1"
import { useAPIClient } from "lib/api/hooks/useAPIClient"
import { useEffect, useState } from "react"
import { Region } from "../client"

interface Props {
  pageToken?: string
  pageSize?: number
  region: Region
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useListSites = (props?: Props) => {
  const { pageSize = 50, pageToken, region = "REGION_UNSPECIFIED" } = props || {}
  const newClient = useAPIClient(region)

  const query = useInfiniteQuery(
    ["ListSites", region, pageSize, pageToken],
    async (context): Promise<ListSitesResponse> => {
      try {
        return await newClient?.PathService.ListSites({
          pageSize: props?.pageSize,
          pageToken: context.pageParam || pageToken,
        })
      } catch (err) {
        console.error(err)
        return {} as ListSitesResponse
      }
    },
    {
      enabled: !!newClient,
      getNextPageParam: (page) => page?.nextPageToken || undefined,
    },
  )

  const [data, setData] = useState<Site[]>([])
  useEffect(() => {
    setData(convertFromMapToList(query?.data))
  }, [query?.data])

  return {
    ...query,
    data,
  }
}

const convertFromMapToList = (data?: InfiniteData<ListSitesResponse | undefined>): Site[] =>
  data?.pages.flatMap((page) => page?.sites || []) ?? []
