import { LayersList } from "@deck.gl/core/typed"
import { IconLayer } from "@deck.gl/layers/typed"
import { useColorScheme } from "@einride/ui"
import { FeatureCollection, Position } from "geojson"
import { useEffect, useState } from "react"
import checkeredFlag from "../assets/checkeredFlag.svg"

export const useGoalLayer = (geojson?: FeatureCollection): LayersList => {
  const [goalFeature, setGoalFeature] = useState<Position | null>(null)
  const { colorScheme } = useColorScheme()

  useEffect(() => {
    if (!geojson) {
      return
    }
    const lastSection = geojson.features.findLast(
      // Paths without geofence have features with id "PathSection"
      // Paths with geofence have features with ids "Reference", "Left" and "Right"
      (item) => item.id === "Reference" || item.id === "PathSection",
    )
    if (lastSection && lastSection.geometry.type === "LineString") {
      setGoalFeature(lastSection.geometry.coordinates.at(-1) ?? null)
    }
  }, [geojson])

  if (!geojson) {
    return []
  }

  return [
    new IconLayer<Position>({
      id: "goal",
      data: [goalFeature],
      getColor: () => {
        return colorScheme === "light" ? [0, 0, 0] : [255, 255, 255]
      },
      getPosition: (d: Position) => [d[0], d[1]],
      getSize: 30,
      getIcon: () => {
        return {
          url: checkeredFlag,
          width: 24,
          height: 24,
          anchorX: 5,
          anchorY: 24,
          mask: true,
        }
      },
    }),
  ]
}
