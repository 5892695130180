import { ConnectError } from "@connectrpc/connect"
import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { Path } from "gen/einride/aet_path_service/v1"
import { Region } from "lib/api/client"
import { useAPIClient } from "lib/api/hooks/useAPIClient"

interface Props {
  region: Region
  name: string | undefined
}

export type GetPathResult = {
  path: Path | undefined
} & UseQueryResult<Path>

export const useGetPath = (props?: Props): GetPathResult => {
  const { region = "REGION_UNSPECIFIED", name } = props || {}
  const client = useAPIClient(region)

  const query = useQuery<Path, ConnectError>(
    ["GetPath", region, name],
    (): Promise<Path> => {
      if (name === undefined || !name) {
        return Promise.reject(new Error("missing name"))
      }
      return client.PathService.GetPath({ name })
    },
    {
      enabled: !!client,
      retry: false,
    },
  )
  return { ...query, path: query?.data }
}
