// @generated by protoc-gen-connect-es v1.1.2 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/remote_interface_action_service.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateActionResponseRequest, CreateRemoteInterfaceActionRequest, GetActionResponseRequest } from "./remote_interface_action_service_pb.js";
import { ActionResponse, RemoteInterfaceAction } from "./remote_interface_action_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Remote interface action service.
 * Linter disabled to remove the need to create ListActionResponses
 * (-- api-linter: core::0121::resource-must-support-list=disabled --)
 *
 * @generated from service einride.rd_operator_interface.v1.RemoteInterfaceActionService
 */
export const RemoteInterfaceActionService = {
  typeName: "einride.rd_operator_interface.v1.RemoteInterfaceActionService",
  methods: {
    /**
     * Get a remote interface.
     *
     * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceActionService.CreateRemoteInterfaceAction
     */
    createRemoteInterfaceAction: {
      name: "CreateRemoteInterfaceAction",
      I: CreateRemoteInterfaceActionRequest,
      O: RemoteInterfaceAction,
      kind: MethodKind.Unary,
    },
    /**
     * Get a action response, i.e. the status of an action
     *
     * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceActionService.GetActionResponse
     */
    getActionResponse: {
      name: "GetActionResponse",
      I: GetActionResponseRequest,
      O: ActionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create a action response. Usually called by ri-manager
     *
     * @generated from rpc einride.rd_operator_interface.v1.RemoteInterfaceActionService.CreateActionResponse
     */
    createActionResponse: {
      name: "CreateActionResponse",
      I: CreateActionResponseRequest,
      O: ActionResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

