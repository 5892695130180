import { LayersList } from "@deck.gl/core/typed"
import { PolygonLayer } from "@deck.gl/layers/typed"
import { StreamVehicleLiveDataResponse } from "gen/einride/rd_operator_interface/v1/vehicle_live_pb"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import "mapbox-gl/dist/mapbox-gl.css"

export const useVehicleObjectLayers = (
  vehicle?: Vehicle,
  vehicleLiveData?: StreamVehicleLiveDataResponse,
): { vehicleObjectLayers: LayersList } => {
  const primaryPolygons: number[][] = []
  vehicleLiveData?.primaryObjects?.objectsBase.forEach((o) => {
    const polygon: number[] = []
    o.polygon?.verticesMetre.forEach((v) => {
      // Mapbox uses lon lat instead of lat lon, that is why y and x is flipped
      polygon.push(v.y, v.x, v.z)
    })
    primaryPolygons.push(polygon)
  })
  const secondaryPolygons: number[][] = []
  vehicleLiveData?.secondaryObjects?.objectsBase.forEach((o) => {
    const polygon: number[] = []
    o.polygon?.verticesMetre.forEach((v) => {
      // Mapbox uses lon lat instead of lat lon, that is why y and x is flipped
      polygon.push(v.y, v.x, v.z)
    })
    secondaryPolygons.push(polygon)
  })

  if (!vehicleLiveData) {
    return { vehicleObjectLayers: [] }
  }

  return {
    vehicleObjectLayers: [
      new PolygonLayer({
        visible: true,
        id: "primary-objects",
        data: primaryPolygons,
        pickable: false,
        stroked: true,
        filled: true,
        wireframe: true,
        lineWidthMinPixels: 1,
        getElevation: 1,
        getPolygon: (data) => data,
        getFillColor: () => [255, 0, 0, 128],
        getLineColor: () => [255, 0, 0],
        getLineWidth: 1,
      }),
      new PolygonLayer({
        visible: true,
        id: "secondary-objects",
        data: secondaryPolygons,
        pickable: false,
        stroked: true,
        filled: true,
        wireframe: true,
        lineWidthMinPixels: 1,
        getElevation: 1,
        getPolygon: (data) => data,
        getFillColor: () => [0, 0, 255, 128],
        getLineColor: () => [0, 0, 255],
        getLineWidth: 1,
      }),
    ],
  }
}
