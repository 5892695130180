import { SearchInput } from "@einride/ui"
import styled from "@emotion/styled"

export const MobileSearchBar = styled(SearchInput)`
  @media (max-width: 530px) {
    font-size: 12px;
  }
  &.extraSearch {
    @media (max-width: 530px) {
      width: 10.5rem;
      font-size: 12px;
    }
  }
`
