import { ConnectError } from "@connectrpc/connect"
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query"
import { RemoteInterface } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { RemoteInterfaceService } from "gen/einride/rd_operator_interface/v1/remote_interface_service_connect"
import { ListRemoteInterfacesResponse } from "gen/einride/rd_operator_interface/v1/remote_interface_service_pb"
import { useEffect, useState } from "react"
import { Region } from "../client"
import { useRegionalizedConnectClient } from "./useAPITransport"

const STALE_TIME_MS = 1000 * 60 * 10 // 10 minutes
const REFETCH_TIME_MS = 1000 * 10 // 10 seconds

interface Props {
  pageToken?: string
  pageSize?: number
  region: Region
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useListRemoteInterfaces = (props: Props) => {
  const { pageSize = 50, pageToken, region } = props
  const client = useRegionalizedConnectClient(RemoteInterfaceService, region)

  const query = useInfiniteQuery<ListRemoteInterfacesResponse, ConnectError>(
    ["listRemoteInterfaces", region, pageSize, pageToken],
    async (context): Promise<ListRemoteInterfacesResponse> => {
      try {
        return await client?.listRemoteInterfaces({
          pageSize: props?.pageSize,
          pageToken: context.pageParam || pageToken,
        })
      } catch (err) {
        console.error(err)
        return {} as ListRemoteInterfacesResponse
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: REFETCH_TIME_MS,
      staleTime: STALE_TIME_MS,
      enabled: !!client,
      getNextPageParam: (page) => page?.nextPageToken || undefined,
    },
  )

  const [data, setData] = useState<RemoteInterface[]>([])
  useEffect(() => {
    setData(convertFromMapToList(query?.data))
  }, [query?.data])

  return {
    ...query,
    data,
  }
}

const convertFromMapToList = (
  data?: InfiniteData<ListRemoteInterfacesResponse | undefined>,
): RemoteInterface[] => data?.pages.flatMap((page) => page?.remoteInterfaces || []) ?? []
