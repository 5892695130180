import { getConfig } from "constants/constants"
import { FirebaseOptions, getApps, initializeApp } from "firebase/app"
import {
  GoogleAuthProvider,
  UserCredential,
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithPopup,
} from "firebase/auth"

const config = getConfig()

export const initFirebaseApp = (): void => {
  // Create and register a new app.
  const firebaseConfig: FirebaseOptions = {
    apiKey: config.firebaseAuth.apiKey,
    authDomain: config.firebaseAuth.authDomain,
    projectId: config.firebaseAuth.projectID,
  }
  initializeApp(firebaseConfig)
}

export const initializeGoogleLogin = async (): Promise<UserCredential> => {
  if (!getApps.length) {
    initFirebaseApp()
  }
  const auth = getAuth()
  await setPersistence(auth, browserLocalPersistence)
  const provider = new GoogleAuthProvider()
  return signInWithPopup(auth, provider)
}
