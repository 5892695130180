import { errorMessages, okMessages, progressMessages } from "constants/actionCodes"
import { useToasts } from "components/Toast"
import {
  RemoteInterfaceAction,
  RemoteInterfaceAction_ConnectAction,
  RemoteInterfaceAction_DisconnectAction,
} from "gen/einride/rd_operator_interface/v1/remote_interface_action_pb"
import { CreateRemoteInterfaceActionRequest } from "gen/einride/rd_operator_interface/v1/remote_interface_action_service_pb"
import { RemoteInterface } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { Vehicle } from "gen/einride/rd_operator_interface/v1/vehicle_pb"
import { useEffect, useState } from "react"
import { Region } from "../client"
import { useCreateRemoteInterfaceAction } from "./useCreateRemoteInterfaceAction"

interface Props {
  region: Region
  remoteInterface?: RemoteInterface
  vehicle?: Vehicle
  version: string
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useVehicleConnection = (props: Props) => {
  const { region, remoteInterface, vehicle, version } = props

  const { toast } = useToasts()

  const [isReady, setIsReady] = useState(false)
  const [state, setState] = useState<"connected" | "connecting" | "disconnected" | "disconnecting">(
    "disconnected",
  )
  const [progress, setProgress] = useState<string | null>(null)
  const { mutateAsync, actionStatus, resetRequestId } = useCreateRemoteInterfaceAction()

  useEffect(() => {
    if (remoteInterface && vehicle) {
      if (
        remoteInterface.status?.connectedVehicle ===
        vehicle.name.substring(vehicle.name.indexOf("/") + 1)
      ) {
        if (!progress) setState("connected")
      } else if (!progress) setState("disconnected")
      setIsReady(true)
    }
  }, [remoteInterface, vehicle, progress])

  useEffect(() => {
    switch (actionStatus?.case) {
      case "error":
        setProgress(null)
        toast({ status: "fail", message: errorMessages[actionStatus.value] })
        resetRequestId()
        break
      case "ok":
        setProgress(null)

        // Optimistically set the connectionstate
        if (okMessages[actionStatus.value].includes("connect")) {
          setState("connected")
        } else {
          setState("disconnected")
        }

        toast({
          status: "success",
          message: okMessages[actionStatus.value],
        })
        resetRequestId()
        break
      case "progress":
        setProgress(progressMessages[actionStatus.value])
        break
      default:
    }
  }, [actionStatus, resetRequestId, toast])

  const connect = async (): Promise<void> => {
    try {
      if (!remoteInterface || !vehicle) {
        throw new Error("missing remoteinterface and/or vehicle")
      }
      setState("connecting")
      setProgress("Starting to connect...")
      resetRequestId()

      await mutateAsync({
        region,
        request: new CreateRemoteInterfaceActionRequest({
          parent: remoteInterface.name,
          remoteInterfaceAction: new RemoteInterfaceAction({
            remoteInterface: remoteInterface.name,
            vehicle: vehicle.name,
            action: {
              case: "connect",
              value: new RemoteInterfaceAction_ConnectAction({
                version,
              }),
            },
            requestingUser: "", // Overwritten in useCreateRemoteInterfaceAction hook def.
          }),
        }),
      })
    } catch (err) {
      setProgress(null)
      toast({ status: "fail", message: `unknown connection error: ${err}` })
    }
  }

  const disconnect = async (): Promise<void> => {
    try {
      if (!remoteInterface || !vehicle) {
        throw new Error("missing remoteinterface and/or vehicle")
      }

      setState("disconnecting")
      setProgress("Starting to disconnect...")
      resetRequestId()

      await mutateAsync({
        region,
        request: new CreateRemoteInterfaceActionRequest({
          parent: remoteInterface.name,
          remoteInterfaceAction: new RemoteInterfaceAction({
            remoteInterface: remoteInterface.name,
            vehicle: vehicle.name,
            action: {
              case: "disconnect",
              value: new RemoteInterfaceAction_DisconnectAction(),
            },
            requestingUser: "", // Overwritten in useCreateRemoteInterfaceAction hook def.
          }),
        }),
      })
    } catch (err) {
      setProgress(null)
      toast({ status: "fail", message: `unknown disconnection error: ${err}` })
    }
  }

  let connectionButtonString
  switch (state) {
    case "connected":
      connectionButtonString = `Disconnect from ${remoteInterface?.displayName}`
      break
    case "connecting":
    case "disconnecting":
      connectionButtonString = progress
      break
    case "disconnected":
      connectionButtonString = isReady ? `Connect to ${remoteInterface?.displayName}` : "Loading..."
      break
    default:
      throw new Error("unknown connection state")
  }

  return {
    connectionState: state,
    connectionProgress: progress,
    isConnectionLoading: state === "connecting" || state === "disconnecting",
    connectionButtonString,
    connect,
    disconnect,
    isConnectionReady: isReady,
  }
}
