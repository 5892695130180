import {
  Group,
  HorizontalLayout,
  Icon,
  PrimaryButton,
  SecondaryButton,
  Sheets,
  Stack,
  Text,
  Textarea,
  Title2,
} from "@einride/ui"
import { TextareaProps } from "@einride/ui/dist/components/controls/textareas/Textarea/Textarea"
import { FeatureCollection, LineString, Point } from "geojson"
import React, { useState } from "react"
import { Path } from "../gen/einride/aet_path_service/v1"
import { useValidatePath } from "../lib/api/hooks/validatePath"
import {
  FeatureCollectionToBase64,
  PathLength,
  PathMaxSpeed,
  VerifyGeoJSONFeatureCollection,
} from "./PathComponents"

type Props = {
  handleAssignPath: (path: Path | undefined) => void
  setSelectedPath: React.Dispatch<React.SetStateAction<Path | undefined>>
  pathSheetOpen: boolean
  setPathSheetOpen: React.Dispatch<React.SetStateAction<boolean>>
  localGeo: FeatureCollection | undefined
  setLocalGeo: React.Dispatch<
    React.SetStateAction<FeatureCollection<LineString | Point> | undefined>
  >
  pathAssignProgress: string | null
}

export const CustomPathSheet = (props: Props): React.JSX.Element => {
  const {
    handleAssignPath,
    setSelectedPath,
    pathSheetOpen,
    setPathSheetOpen,
    localGeo,
    setLocalGeo,
    pathAssignProgress,
  } = props

  const [customPathText, setCustomPathText] = useState("")
  const [customPathTextMessage, setCustomPathTextMessage] = useState("")
  const [customPathTextStatus, setCustomPathTextStatus] =
    useState<TextareaProps["status"]>("neutral")
  const [localPath, setLocalPath] = useState<FeatureCollection<LineString | Point> | undefined>()

  const { mutateAsync } = useValidatePath()

  const onDragoverHandler = (event: React.DragEvent): void => {
    event.stopPropagation()
    event.preventDefault()
    const reader = new FileReader()
    reader.onload = (e) => {
      if (e?.target?.result) {
        handleTextAreaChange(e?.target?.result as string)
      }
    }
    const file = event.dataTransfer.files[0]
    if (["application/geo+json", "application/json"].includes(file.type)) {
      reader.readAsText(file, "UTF-8")
    }
  }

  const handleSheetClose = (): void => {
    setPathSheetOpen(false)
    setLocalGeo(undefined)
  }

  const handleTextAreaChange = (text: string): void => {
    setCustomPathText(text)

    try {
      const lp = JSON.parse(text)
      VerifyGeoJSONFeatureCollection(lp)
      setLocalPath(lp)
      setLocalGeo(lp)
      setCustomPathTextStatus("success")
      setCustomPathTextMessage("")
      mutateAsync({
        region: "REGION_UNSPECIFIED",
        path: convertOldPath(lp),
      }).catch((err) => {
        setCustomPathTextMessage(err.message)
        setCustomPathTextStatus("fail")
      })
    } catch (err: unknown) {
      // @ts-ignore
      setCustomPathTextMessage(err.message)
      setCustomPathTextStatus("fail")
    }
    if (text === "") {
      setLocalPath(undefined)
      setLocalGeo(undefined)
      setCustomPathTextStatus("neutral")
    }
  }

  return (
    <Sheets
      style={{ display: "flex" }}
      closeHandler={handleSheetClose}
      isOpen={pathSheetOpen}
      navigationAction={{
        "aria-label": "close-path-modal",
        icon: "xMark",
        onClick: handleSheetClose,
      }}
      navigationTitle={<Title2>CAUTION!</Title2>}
    >
      <Stack height="100%" justifyContent="flex-start" gap="md" style={{ display: "flex" }}>
        <Stack gap="xs" style={{ height: "100%", display: "flex" }}>
          <Text>
            The paths sent through this box has not been verified by Einride. The vehicle could
            perform unexpected maneuvers.
          </Text>
          <Stack gap="md" style={{ height: "100%", display: "flex" }}>
            <Textarea
              textareaWrapperProps={{ style: { height: "100%" } }}
              onDrop={(event) => onDragoverHandler(event)}
              style={{ height: "100%", display: "flex" }}
              wrapperProps={{
                style: {
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                },
              }}
              aria-label="GeoJSON file contents"
              placeholder="To use a local path, drag and drop the GeoJSON file here or paste the contents of the file"
              onChange={(event) => {
                handleTextAreaChange(event.target.value)
              }}
              value={customPathText}
              status={customPathTextStatus}
              message={customPathTextStatus === "fail" && customPathTextMessage}
            />
          </Stack>
        </Stack>
        <HorizontalLayout gap="lg">
          <PathLength path={localGeo as FeatureCollection<LineString | Point>} />
          <PathMaxSpeed path={localGeo as FeatureCollection<LineString | Point>} />
        </HorizontalLayout>
        <Group>
          <PrimaryButton
            disabled={!localPath || customPathTextStatus === "fail"}
            rightIcon={<Icon name="loupe" />}
            onClick={() => {
              setLocalGeo(localPath)
              setSelectedPath(undefined)
              setPathSheetOpen(false)
            }}
          >
            Preview path
          </PrimaryButton>
          <SecondaryButton
            disabled={!localPath || customPathTextStatus === "fail" || !!pathAssignProgress}
            isLoading={!!pathAssignProgress}
            rightIcon={<Icon name="arrowRight" />}
            onClick={() => {
              setLocalGeo(undefined)
              setSelectedPath(undefined)
              if (localPath) {
                handleAssignPath(convertOldPath(localPath))
              }
            }}
            data-testid="assign-custom-vehicle-path-button"
          >
            {pathAssignProgress ?? "Assign local path"}
          </SecondaryButton>
        </Group>
      </Stack>
    </Sheets>
  )
}

const convertOldPath = (path: FeatureCollection<LineString | Point>): Path => {
  return {
    name: "",
    displayName: "",
    path: FeatureCollectionToBase64(path),
    version: "",
    subSite: "",
  }
}
