import styled from "@emotion/styled"
import { ComponentPropsWithoutRef, ReactNode, forwardRef } from "react"

type Variant = "primary" | "secondary" | "tertiary"

interface StyledBaseButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant: Variant
}

interface top extends ComponentPropsWithoutRef<"button"> {
  variant?: Variant
  children: ReactNode
}

// Slightly modified version of the IconButton from Einride-UI to enable passing children.
const StyledBaseButton = styled.button<StyledBaseButtonProps>`
  inline-size: auto;
  block-size: auto;
  flex-shrink: 0; // prevent shrinking in flex layouts
  border-radius: ${({ theme }) => theme.borderRadii.full};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, variant }) => theme.colors.buttons.background[variant]};
  color: ${({ theme, variant }) => theme.colors.buttons.text[variant]};
  overflow: hidden;

  &:disabled {
    background: ${({ theme, variant }) => theme.colors.buttons.background.disabled[variant]};
    color: ${({ theme }) => theme.colors.buttons.text.disabled};
    text-decoration: none;
  }

  &:not(:disabled) {
    &:hover {
      background: ${({ theme, variant }) => theme.colors.buttons.background.hover[variant]};
      text-decoration: none;
    }

    &:focus-visible {
      background: ${({ theme, variant }) => theme.colors.buttons.background.focused[variant]};
      text-decoration: none;
      inline-size: auto;
    }
  }
`

export const CustomIconButton = forwardRef<HTMLButtonElement, top>(
  ({ variant = "secondary", children, ...props }, ref) => {
    return (
      /* eslint-disable react/jsx-props-no-spreading */
      <StyledBaseButton variant={variant} {...props} ref={ref}>
        {children}
      </StyledBaseButton>
    )
  },
)
