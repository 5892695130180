// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/vehicle.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { LatLng } from "../../../google/type/latlng_pb.js";

/**
 * Vehicle is a simplified version of the full message definition in aet-registry,
 * it only contains fields relevant to remote operator interface.
 *
 * @generated from message einride.rd_operator_interface.v1.Vehicle
 */
export class Vehicle extends Message<Vehicle> {
  /**
   * The resource name of the vehicle.
   * Pattern: vehicles/{vehicleId}, where vehicleId is a base32-encoded UUID.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * display name of the entity. E.g "Bruce1"
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * Ephemeral state fields goes here, like state of charge etc.
   *
   * @generated from field: einride.rd_operator_interface.v1.Vehicle.State state = 3;
   */
  state?: Vehicle_State;

  /**
   * System deployments.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.Vehicle.Deployment deployments = 4;
   */
  deployments: Vehicle_Deployment[] = [];

  /**
   * Remote version (found remote-operate/oden-streamer version.
   *
   * @generated from field: string remote_version = 5;
   */
  remoteVersion = "";

  constructor(data?: PartialMessage<Vehicle>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.Vehicle";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "message", T: Vehicle_State },
    { no: 4, name: "deployments", kind: "message", T: Vehicle_Deployment, repeated: true },
    { no: 5, name: "remote_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vehicle {
    return new Vehicle().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vehicle {
    return new Vehicle().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vehicle {
    return new Vehicle().fromJsonString(jsonString, options);
  }

  static equals(a: Vehicle | PlainMessage<Vehicle> | undefined, b: Vehicle | PlainMessage<Vehicle> | undefined): boolean {
    return proto3.util.equals(Vehicle, a, b);
  }
}

/**
 * Basic last seen information.
 *
 * @generated from message einride.rd_operator_interface.v1.Vehicle.State
 */
export class Vehicle_State extends Message<Vehicle_State> {
  /**
   * When we last saw traffic from this pod.
   *
   * @generated from field: google.protobuf.Timestamp last_seen_time = 1;
   */
  lastSeenTime?: Timestamp;

  /**
   * The last location reported (may be older than last_seen_time).
   *
   * @generated from field: google.type.LatLng last_seen_gnss = 2;
   */
  lastSeenGnss?: LatLng;

  /**
   * Timestamp corresponding to the GNSS coordinates, may be (much) older than last_seen_time.
   *
   * @generated from field: google.protobuf.Timestamp last_seen_gnss_time = 3;
   */
  lastSeenGnssTime?: Timestamp;

  constructor(data?: PartialMessage<Vehicle_State>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.Vehicle.State";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "last_seen_time", kind: "message", T: Timestamp },
    { no: 2, name: "last_seen_gnss", kind: "message", T: LatLng },
    { no: 3, name: "last_seen_gnss_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vehicle_State {
    return new Vehicle_State().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vehicle_State {
    return new Vehicle_State().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vehicle_State {
    return new Vehicle_State().fromJsonString(jsonString, options);
  }

  static equals(a: Vehicle_State | PlainMessage<Vehicle_State> | undefined, b: Vehicle_State | PlainMessage<Vehicle_State> | undefined): boolean {
    return proto3.util.equals(Vehicle_State, a, b);
  }
}

/**
 * Information about a sw deployment.
 *
 * @generated from message einride.rd_operator_interface.v1.Vehicle.Deployment
 */
export class Vehicle_Deployment extends Message<Vehicle_Deployment> {
  /**
   * Name of the deployed software.
   *
   * @generated from field: string deployment = 1;
   */
  deployment = "";

  /**
   * Version of the deployed software.
   *
   * @generated from field: string version = 2;
   */
  version = "";

  constructor(data?: PartialMessage<Vehicle_Deployment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.Vehicle.Deployment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deployment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vehicle_Deployment {
    return new Vehicle_Deployment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vehicle_Deployment {
    return new Vehicle_Deployment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vehicle_Deployment {
    return new Vehicle_Deployment().fromJsonString(jsonString, options);
  }

  static equals(a: Vehicle_Deployment | PlainMessage<Vehicle_Deployment> | undefined, b: Vehicle_Deployment | PlainMessage<Vehicle_Deployment> | undefined): boolean {
    return proto3.util.equals(Vehicle_Deployment, a, b);
  }
}

