type Request = {
  path: string
  method: string
  body: string | null
}

export class ResponseError extends Error {
  errorCode: number

  constructor(message: string, code: number) {
    super(message)
    this.errorCode = code
    this.name = this.constructor.name
  }
}

export const createRequestHandler = (
  getToken: () => Promise<string> | undefined,
  baseUrl: string,
) => {
  return async ({ path, method, body }: Request): Promise<unknown> => {
    const url = new URL("", baseUrl)
    const headers = new Headers()

    const token = await getToken()
    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    } else {
      // eslint-disable-next-line no-console
      console.warn("no auth token provided")
    }

    const [pathname, query] = path.split("?")
    if (pathname !== undefined) {
      url.pathname = pathname
    }
    if (query !== undefined) {
      url.search = query
    }

    const request = new Request(url.toString(), {
      method,
      ...(body && { body }),
      headers,
    })
    return fetch(request)
      .catch(() => {
        throw new Error("defaultServerError")
      })
      .then(async (response) => {
        if (response.status !== 200) {
          const responseBody = await response.json()
          throw new ResponseError(responseBody.message, response.status)
        }
        if (response.headers.get("content-type") === "application/json") {
          return response.json()
        }
        return response.text()
      })
  }
}
