import { InfiniteData, UseInfiniteQueryResult, useInfiniteQuery } from "@tanstack/react-query"
import { ListPathsResponse, Path } from "gen/einride/aet_path_service/v1"
import { useAPIClient } from "lib/api/hooks/useAPIClient"
import { Region } from "../client"

interface Props {
  pageToken?: string
  pageSize?: number
  region: Region
  parent: string
}

export type ListPathsResult = {
  paths: Path[]
} & UseInfiniteQueryResult<ListPathsResponse>

export const useListPaths = (props?: Props): ListPathsResult => {
  const { pageSize = 50, pageToken, region = "REGION_UNSPECIFIED", parent } = props || {}
  const newClient = useAPIClient(region)

  const query = useInfiniteQuery(
    ["ListPaths", region, pageSize, pageToken, parent],
    async (context): Promise<ListPathsResponse> => {
      if (!parent) {
        return {} as ListPathsResponse
      }
      try {
        return await newClient?.PathService.ListPaths({
          parent,
          pageSize: props?.pageSize,
          pageToken: context.pageParam || pageToken,
          view: "PATH_VIEW_BASIC",
        })
      } catch (err) {
        console.error(err)
        return {} as ListPathsResponse
      }
    },
    {
      enabled: !!newClient,
      getNextPageParam: (page) => page?.nextPageToken || undefined,
    },
  )
  return { ...query, paths: convertFromMapToList(query?.data) }
}

const convertFromMapToList = (data?: InfiniteData<ListPathsResponse | undefined>): Path[] =>
  data?.pages.flatMap((page) => page?.paths || []) ?? []
