import styled from "@emotion/styled"
import { useAuth } from "contexts/AuthContext"
import React from "react"
import { Outlet } from "react-router-dom"
import { LoginPage } from "./LoginPage"

export const StyledRootLayout = styled.div`
  @media (max-width: 530px) {
    font-size: 12px;
  }
`
export const RootLayout = (): React.JSX.Element => {
  const auth = useAuth()

  if (auth.loginState !== "successful") {
    return <LoginPage />
  }
  return (
    <StyledRootLayout>
      <Outlet />
    </StyledRootLayout>
  )
}
