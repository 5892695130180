import { UseDisclosureHandlers, usePrefersColorScheme } from "@einride/hooks"
import { Card, Paragraph, Segments, SegmentsItem, Sheets, Stack, useColorScheme } from "@einride/ui"
import styled from "@emotion/styled"
import React, { useEffect, useState } from "react"
import { StyledTitle1 } from "./StyledTitle1"

const SettingWrapper = styled.div`
  @media (max-width: 530px) {
    scale: 1;
    font-size: 9px;
  }
`
type Props = {
  handlers: UseDisclosureHandlers
  isOpen: boolean
}

type SagaColorMode = "light" | "dark" | "system"
export type UnitSystem = "metric" | "US"

export const useUnitSystem = (): UnitSystem => {
  return localStorage.getItem("unitSystem") as UnitSystem
}

export const SettingsSheet = (props: Props): React.JSX.Element => {
  const { handlers, isOpen } = props

  const { setColorScheme } = useColorScheme()
  const systemColor = usePrefersColorScheme()
  const [selectedColor, setSelectedColor] = useState<SagaColorMode>(
    (localStorage.getItem("preferredColorTheme") as SagaColorMode) || "system",
  )
  const [unitSystem, setUnitSystem] = useState<UnitSystem>(
    (localStorage.getItem("unitSystem") as UnitSystem) || "metric",
  )

  useEffect(() => {
    localStorage.setItem("preferredColorTheme", selectedColor)
    if (selectedColor === "system") {
      setColorScheme(systemColor)
      return
    }
    setColorScheme(selectedColor)
  }, [systemColor, selectedColor, setColorScheme])

  const handleUnitSystem = (sys: UnitSystem): void => {
    localStorage.setItem("unitSystem", sys)
    setUnitSystem(sys)
  }

  return (
    <Sheets
      style={{ width: "360px" }}
      closeHandler={handlers.close}
      isOpen={isOpen}
      navigationAction={{
        "aria-label": "close-path-modal",
        icon: "xMark",
        onClick: handlers.close,
      }}
      navigationTitle={<StyledTitle1>Settings</StyledTitle1>}
    >
      <SettingWrapper>
        <Stack gap="sm">
          <Card background="secondary">
            <Paragraph>Appearance</Paragraph>
            <Segments
              onValueChange={(value) => setSelectedColor(value as SagaColorMode)}
              value={selectedColor}
            >
              <SegmentsItem value="light">Light</SegmentsItem>
              <SegmentsItem value="dark">Dark</SegmentsItem>
              <SegmentsItem value="system">System</SegmentsItem>
            </Segments>
          </Card>
          <Card background="secondary">
            <Paragraph>Unit System</Paragraph>
            <Segments
              onValueChange={(value) => handleUnitSystem(value as UnitSystem)}
              value={unitSystem}
            >
              <SegmentsItem value="metric">Metric</SegmentsItem>
              <SegmentsItem value="US">Imperial</SegmentsItem>
            </Segments>
          </Card>
        </Stack>
      </SettingWrapper>
    </Sheets>
  )
}
