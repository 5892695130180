import { Timestamp } from "@bufbuild/protobuf"
import { Banner, Box, Group, Text } from "@einride/ui"
import styled from "@emotion/styled"
import moment from "moment"
import { ReactElement } from "react"

const StyledText = styled(Text)`
  @media (max-width: 530px) {
    font-size: 12px;
  }
`
const BannerContainer = styled.div`
  @media (max-width: 530px) {
    scale: 0.8;
    font-size: 14px;
  }
`

type LastSeenWidgetProps = { time?: Timestamp }
export const LastSeenWidget = ({ time }: LastSeenWidgetProps): ReactElement => {
  const online =
    time === undefined
      ? false
      : moment(new Date(time.toDate())).isAfter(moment().subtract(5, "minutes"))

  return (
    <Box display="flex" alignItems="center">
      <Group>
        <BannerContainer>
          <Banner status={online ? "success" : "fail"} title={online ? "Online" : "Offline"} />
        </BannerContainer>
        {online && (
          <StyledText>
            Last seen: <br /> {moment(time?.toDate()).fromNow()}
          </StyledText>
        )}
      </Group>
    </Box>
  )
}
