import { Text } from "@einride/ui"
import styled from "@emotion/styled"

export const StyledText = styled(Text)`
  @media (max-width: 530px) {
    font-size: 12px;
  }
  &.selectVehicle {
    font-size: 18px;
  }
`
