import { User, getAuth, getRedirectResult } from "firebase/auth"
import { initFirebaseApp, initializeGoogleLogin } from "lib/auth/firebase"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"

export type LoginState = "successful" | "error" | "pending" | "notStarted"
export type TokenProvider = () => Promise<string>

type AuthContextValue = {
  token: TokenProvider
  user: User | undefined
  loginState: LoginState
  signOut: () => Promise<void>
  signIn: () => Promise<void>
}

const emptyToken = (): Promise<never> => Promise.reject(new Error("no token available"))

export const Context = createContext<AuthContextValue | null>({
  loginState: "notStarted",
  token: emptyToken,
  user: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
})

interface AuthContextProps {
  children: React.ReactNode
}

export const AuthContext = ({ children }: AuthContextProps): React.JSX.Element => {
  const [user, setUser] = useState<User | undefined>()
  const [loginState, setLoginState] = useState<LoginState>("notStarted")
  initFirebaseApp()
  const auth = getAuth()

  useEffect(() => {
    return auth.onAuthStateChanged(async (u) => {
      if (u) {
        setUser(u)
        setLoginState("successful")
      } else {
        setUser(undefined)
        setLoginState("notStarted")
      }
    })
  }, [auth])

  const value = useMemo(() => {
    const login = async (): Promise<void> => {
      setLoginState("pending")
      try {
        const credentials = await getRedirectResult(auth)
        if (credentials) {
          setLoginState("successful")
          setUser(credentials.user)
        } else {
          await initializeGoogleLogin()
        }
      } catch {
        setLoginState("error")
      }
    }

    return {
      token: () => user?.getIdToken() ?? emptyToken(),
      user,
      loginState,
      signOut: () => auth.signOut(),
      signIn: login,
    }
  }, [user, loginState, auth])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useAuth(): AuthContextValue {
  const auth = useContext(Context)

  if (!auth) {
    throw new Error("useAuth must be used within a AuthContext")
  }

  return auth
}
