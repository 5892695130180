import { Td, Tr } from "@einride/ui"
import styled from "@emotion/styled"
import { LastSeenWidget } from "components/LastSeenWidget"
import { ConnectionStatus as ConnectionStatusProto } from "gen/einride/rd_operator_interface/v1/remote_interface_pb"
import { useNavigate } from "react-router-dom"

const StyledTd = styled(Td)`
  text-decoration: underline;
  cursor: pointer;
`

type RemoteInterfaceProps = {
  name: string
  displayName: string
  location: string
  status?: ConnectionStatusProto
}

export const RemoteInterface: React.FC<RemoteInterfaceProps> = ({
  name,
  displayName,
  location,
  status,
}) => {
  const navigate = useNavigate()

  return (
    <Tr key={name}>
      <StyledTd scope="row" onClick={() => navigate(`/${name}/vehicles`)}>
        {displayName}
      </StyledTd>
      <Td>{location}</Td>
      <Td>{status?.connectedVehicle?.split("/").at(-1) || "Idle"}</Td>
      <Td>
        <LastSeenWidget time={status?.updateTime} />
      </Td>
    </Tr>
  )
}
