import { useAuth } from "contexts/AuthContext"
import { PathService } from "gen/einride/aet_path_service/v1"
import { Region, createRegionalAPIClient } from "lib/api/client"

export const useAPIClient = (
  region: Region,
): {
  PathService: PathService
} => {
  const { user } = useAuth()
  const token = user?.getIdToken()

  return createRegionalAPIClient(() => token)[region]
}
