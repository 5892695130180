// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file einride/rd_operator_interface/v1/remote_interface_service.proto (package einride.rd_operator_interface.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ConnectionStatus, RemoteInterface } from "./remote_interface_pb.js";

/**
 * Request to create a connection status
 *
 * @generated from message einride.rd_operator_interface.v1.CreateConnectionStatusRequest
 */
export class CreateConnectionStatusRequest extends Message<CreateConnectionStatusRequest> {
  /**
   * The resource name of the parent remote interface
   *
   * @generated from field: string parent = 1;
   */
  parent = "";

  /**
   * ID of the connection status
   *
   * @generated from field: string connection_status_id = 2;
   */
  connectionStatusId = "";

  /**
   * The connection status of the station
   *
   * @generated from field: einride.rd_operator_interface.v1.ConnectionStatus connection_status = 3;
   */
  connectionStatus?: ConnectionStatus;

  constructor(data?: PartialMessage<CreateConnectionStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.CreateConnectionStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "connection_status_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "connection_status", kind: "message", T: ConnectionStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateConnectionStatusRequest {
    return new CreateConnectionStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateConnectionStatusRequest {
    return new CreateConnectionStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateConnectionStatusRequest {
    return new CreateConnectionStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateConnectionStatusRequest | PlainMessage<CreateConnectionStatusRequest> | undefined, b: CreateConnectionStatusRequest | PlainMessage<CreateConnectionStatusRequest> | undefined): boolean {
    return proto3.util.equals(CreateConnectionStatusRequest, a, b);
  }
}

/**
 * Request to GetRemoteInterface.
 *
 * @generated from message einride.rd_operator_interface.v1.GetRemoteInterfaceRequest
 */
export class GetRemoteInterfaceRequest extends Message<GetRemoteInterfaceRequest> {
  /**
   * Resource name of the vehicle to retrieve.
   *
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<GetRemoteInterfaceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.GetRemoteInterfaceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRemoteInterfaceRequest {
    return new GetRemoteInterfaceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRemoteInterfaceRequest {
    return new GetRemoteInterfaceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRemoteInterfaceRequest {
    return new GetRemoteInterfaceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRemoteInterfaceRequest | PlainMessage<GetRemoteInterfaceRequest> | undefined, b: GetRemoteInterfaceRequest | PlainMessage<GetRemoteInterfaceRequest> | undefined): boolean {
    return proto3.util.equals(GetRemoteInterfaceRequest, a, b);
  }
}

/**
 * Request to ListRemoteInterfaces.
 *
 * @generated from message einride.rd_operator_interface.v1.ListRemoteInterfacesRequest
 */
export class ListRemoteInterfacesRequest extends Message<ListRemoteInterfacesRequest> {
  /**
   * Requested page size. Server may return fewer results than requested.
   * If unspecified, at most 100 results will be returned.
   * The maximum value is 1000.
   *
   * @generated from field: int32 page_size = 1;
   */
  pageSize = 0;

  /**
   * A token identifying a page of results the server should return.
   * Typically, this is the value of `next_page_token` returned from
   * the previous call to `ListRemoteInterfaces` method.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken = "";

  constructor(data?: PartialMessage<ListRemoteInterfacesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.ListRemoteInterfacesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRemoteInterfacesRequest {
    return new ListRemoteInterfacesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRemoteInterfacesRequest {
    return new ListRemoteInterfacesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRemoteInterfacesRequest {
    return new ListRemoteInterfacesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRemoteInterfacesRequest | PlainMessage<ListRemoteInterfacesRequest> | undefined, b: ListRemoteInterfacesRequest | PlainMessage<ListRemoteInterfacesRequest> | undefined): boolean {
    return proto3.util.equals(ListRemoteInterfacesRequest, a, b);
  }
}

/**
 * Response from ListRemoteInterfaces.
 *
 * @generated from message einride.rd_operator_interface.v1.ListRemoteInterfacesResponse
 */
export class ListRemoteInterfacesResponse extends Message<ListRemoteInterfacesResponse> {
  /**
   * The list of ListRemoteInterfaces.
   *
   * @generated from field: repeated einride.rd_operator_interface.v1.RemoteInterface remote_interfaces = 1;
   */
  remoteInterfaces: RemoteInterface[] = [];

  /**
   * A token to retrieve next page of results. Pass this value in the `page_token`
   * field in the subsequent call to `ListRemoteInterfaces` method to retrieve the next
   * page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<ListRemoteInterfacesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "einride.rd_operator_interface.v1.ListRemoteInterfacesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "remote_interfaces", kind: "message", T: RemoteInterface, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRemoteInterfacesResponse {
    return new ListRemoteInterfacesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRemoteInterfacesResponse {
    return new ListRemoteInterfacesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRemoteInterfacesResponse {
    return new ListRemoteInterfacesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListRemoteInterfacesResponse | PlainMessage<ListRemoteInterfacesResponse> | undefined, b: ListRemoteInterfacesResponse | PlainMessage<ListRemoteInterfacesResponse> | undefined): boolean {
    return proto3.util.equals(ListRemoteInterfacesResponse, a, b);
  }
}

