import {
  ActionError,
  ActionOK,
  ActionProgress,
} from "gen/einride/rd_operator_interface/v1/remote_interface_action_pb"

export const progressMessages: { [key in ActionProgress]: string } = {
  [ActionProgress.ACTION_PROGRESS_UNSPECIFIED]: "Internal error",
  [ActionProgress.STARTING_CONNECTION_ATTEMPT]: "Starting connection attempt",
  [ActionProgress.CONNECTING_TO_VPN]: "Connecting to VPN",
  [ActionProgress.DOWNLOADING_SOFTWARE_PACKAGE]: "Downloading software package",
  [ActionProgress.INSTALLING_ODEN_DOME_PLAYER]: "Installing oden dome player",
  [ActionProgress.STARTING_STATION_SOFTWARE]: "Starting station software",
  [ActionProgress.CONNECTING_TO_VEHICLE]: "Connecting to vehicle",
  [ActionProgress.PROCESSING_ACTION]: "Processing action",
}

export const errorMessages: { [key in ActionError]: string } = {
  [ActionError.ACTION_ERROR_UNSPECIFIED]: "Internal error",
  [ActionError.TIMEOUT]: "Action timed out",
  [ActionError.VPN_CONNECTION_FAILED]: "VPN connection failed",
  [ActionError.SOFTWARE_DOWNLOAD_FAILED]: "Software download failed",
  [ActionError.ODEN_DOME_PLAYER_INSTALL_FAILED]: "Oden dome plyaer install failed",
  [ActionError.STATION_PROCESS_ALREADY_RUNNING]:
    "Another process is already running on the station",
  [ActionError.STATION_SOFTWARE_START_FAILED]: "Failed to start station software",
  [ActionError.STOP_STATION_PROCESSES_FAILED]: "Failed to stop station processes",
  [ActionError.INCORRECT_REQUESTING_USER]: "Incorrect requesting user",
  [ActionError.STATION_NOT_CONNECTED]: "Failed to disconnect. Station is not connected",
  [ActionError.VEHICLE_NOT_FOUND]: "Vehicle not found",
}

export const okMessages: { [key in ActionOK]: string } = {
  [ActionOK.ACTION_OK_UNSPECIFIED]: "Internal error",
  [ActionOK.CONNECT_SUCCESS]: "Successfully connected",
  [ActionOK.DISCONNECT_SUCCESS]: "Successfully disconnected",
  [ActionOK.PATH_ASSIGN_SUCCESS]: "Successfully assigned a path",
}
