import { Box, Link, Text, VerticalSpacing } from "@einride/ui"
import React from "react"
import { useLocation } from "react-router-dom"

export const NotFoundPage = (): React.JSX.Element => {
  return (
    <Box padding="sm">
      <Text as="h1" variant="titleLg">
        Page Not Found
      </Text>
      <VerticalSpacing />
      <Text>The requested path {useLocation().pathname} was not found. </Text>
      <Link href="/">Back to Start</Link>
    </Box>
  )
}
